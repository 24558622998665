/**
 * Components Utility Functions
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

/**
 * Create a jQuery wrapped plain object for custom event handling.
 * This is helpful for async-loaded third party dependencies (like Brightcove) ...
 * Listen for a custom event - like $(document).on()
 * Components.$events.on('brightcoveLoaded');
 * Trigger a custom event - like $(document).trigger()
 * Components.$events.trigger('brightcoveLoaded');
 */
Components.$events = jQuery({});

// Declare utils namespace.
Components.utils = {};

// Breakpoint values.
Components.utils.breakpoints = {
  mobileMax: 639,
  tabletMin: 640,
  tabletMax: 960,
  desktopMin: 961,
  contentMax: 1550,
  layoutMax: 1920
};

/**
 * Smooth Scroll to top of an element
 * @param  {jQuery Object} $element - Element to scroll to the top of
 * @param  {integer} duration       - Length of the animation
 * @param  {integer} offset         - Any offset to account for sticky elements
 * @param  {boolean} onlyUp         - Whether scroll should only happen if the scroll direction is up
 */
Components.utils.smoothScrollTop = function ($element, duration, offset, onlyUp) {
  duration = typeof duration === "number" ? duration : 500;
  offset = offset || 0;
  onlyUp = onlyUp || false;

  var elementTop = $element.offset().top,
      pageTop = $(window).scrollTop(),
      scroll = !onlyUp;

  if (onlyUp && pageTop > elementTop) {
    scroll = true;
  }

  if (scroll) {
    $('body, html').animate({
      scrollTop: elementTop - offset
    }, duration);
  }
};

/**
 * Get parsed URL params, with caching.
 *
 * @return {Object} URL Params
 */
Components.utils.getUrlParams = function () {
  var urlParams = Components.utils.parseUrlParams;
  // Return the cached result, or on cache miss, the result of the invoked
  // function, assigned to the cache property of this Function object.
  return urlParams.cache || (urlParams.cache = urlParams());
};

/**
 * Get parsed URL params.
 *
 * @return {Object} URL Params
 */
Components.utils.parseUrlParams = function () {
  var query = window.location.search.substring(1);
  var result = {};
  var match;
  var search = /([^&=]+)=?([^&]*)/g;
  var decode = function (s) {
    // This needs a try catch because we may get bad param values like
    // &dclid=%edclid! which look like a percent-encoded uri component
    // but isn't and will error.
    try {
      return decodeURIComponent(s.replace(/\+/g, ' '));
    } catch (e) {
      return '';
    }
  };

  while ((match = search.exec(query)) !== null) {
    if (decode(match[1]) && decode(match[2])) {
      result[decode(match[1])] = decode(match[2]);
    }
  }

  return result;
};


/**
 * Helper to identify which breakpoint the browser is in.
 * @param  {string} layout - the layout mode to check for.
 * @return {Boolean} whether viewport is within specified breakpoint
 * @example Components.utils.breakpoint('mobile') - true if in mobile layout
 */
Components.utils.breakpoint = function (layout) {
  // Fail fast if matchMedia isn't present.
  if (typeof window.matchMedia !== 'function') {
    return false;
  }

  switch (layout) {
    case 'mobile':
      return matchMedia('(max-width: ' + Components.utils.breakpoints.mobileMax + 'px)').matches;
      break;
    case 'tablet':
      return matchMedia('(min-width:' + Components.utils.breakpoints.tabletMin + 'px) and (max-width: ' + Components.utils.breakpoints.tabletMax + 'px)').matches;
      break;
    case 'desktop':
      return matchMedia('(min-width: ' + Components.utils.breakpoints.desktopMin + 'px)').matches;
      break;
    default:
      return false;
  }
};

/**
 * Return the matching breakpoint name (mobile, tablet, or desktop).
 * Similar to Components.utils.breakpoint but returns the active breakpoint name instead.
 *
 * @todo refactor all existing usages of Components.utils.breakpoint to use this function.
 *
 * @returns {String}
 */
Components.utils.getBreakpoint = function () {
  var isTablet,
      isDesktop;

  // Fail fast if matchMedia isn't present. Assume desktop.
  if (typeof window.matchMedia !== 'function') {
    return 'desktop';
  }

  isTablet = matchMedia(
    '(min-width:' + Components.utils.breakpoints.tabletMin + 'px) and (max-width: ' +
    Components.utils.breakpoints.tabletMax + 'px)'
  ).matches;

  isDesktop = matchMedia(
    '(min-width: ' + Components.utils.breakpoints.desktopMin + 'px)'
  ).matches;

  if (isDesktop) {
    return 'desktop';
  }
  else if (isTablet) {
    return 'tablet';
  }
  else {
    return 'mobile';
  }
};

/**
 * Helper function to get the element's viewport center.
 * @param $element
 *
 * @returns string
 *  y position
 */
Components.utils.getElementViewPortCenter = function ($element) {
  var scrollTop = $(window).scrollTop(),
    scrollBot = scrollTop + $(window).height(),
    elHeight = $element.outerHeight(),
    elTop = $element.offset().top,
    elBottom = elTop + elHeight,
    elTopOffset = elTop < scrollTop ? scrollTop - elTop : 0,
    elBottomOffset = elBottom > scrollBot ? scrollBot - elTop : elHeight;

  // Return 50% if entire element is visible.
  if (elTopOffset === 0 && elBottomOffset === elHeight) {
    return '50%';
  }

  return Math.round(elTopOffset + ((elBottomOffset - elTopOffset) / 2)) + 'px';
};

/**
 * Helper function to decide the duration of an animation
 * @param {integer} distance - The distance needed to travel (such as height of an element that is expanding)
 * @param {integer} speed - Pixels per second. Defaults to 1000
 *
 * @returns integer
 *  milliseconds for animation duration
 */
Components.utils.animationDuration = function (distance, speed) {
  // Set default speed of 1000 px per second
  var speed = speed || 1000;

  return (distance/speed) * 1000;
};

/**
 * Helper function to decide the next logical heading level.
 *
 * In order to maintain best practices for A11y, the document structure should be in order.
 * See https://www.w3.org/WAI/tutorials/page-structure/headings/.
 * This can be useful for programmatically setting the next heading level
 * for an element or group of elements. See jquery.accordion.js as example.
 * @param {jQuery Object} $startElement - The element where we start the traverse.
 *
 * @returns {string|null}
 *  H1 - H6 to insert as a tag.
 */
Components.utils.setNextHeadingLevelA11y = function ($startElement) {
  // Recursive function to find the closest previous headline.
  function findPreviousHeadline($element) {
    // If we've reached the body or html, there are no more elements to check.
    if ($element.is('body') || $element.is('html')) {
      return null;
    }

    // First check if there are any immediate headline siblings.
    let $found = $element.prevAll('h1,h2,h3,h4,h5,h6').first();
    if ($found.length) {
      return $found;
    }
    // Otherwise check inside siblings for nested headlines except the accordion
    // content wrapper. This is to prevent the nested accordion contents from
    // being considered when determining the headline structure.
    let $foundNested = $element.prevAll().filter(function() {
      return $(this).find('.accordion__content-wrapper').length === 0;
    }).find('h1,h2,h3,h4,h5,h6').last();
    if ($foundNested.length) {
      return $foundNested;
    }

    // If no previous sibling or headline found, move up to the parent.
    return findPreviousHeadline($element.parent());
  }

  // Start the search from the provided element.
  const $previousHeadline = findPreviousHeadline($startElement);
  // Determine the next headline level based on the found headline.
  if ($previousHeadline) {
    const currentLevel = parseInt($previousHeadline.prop('tagName').substring(1), 10);
    if (currentLevel < 6) {
      // Next level, if current is less than h6
      return `h${currentLevel + 1}`;
    }
    // Stay at h6 if it's already the deepest level.
    return 'h6';
  }
  // This could default to h1, but to be safe, default to h2 since this will
  // most likely be components on the page and not the main headline.
  return 'h2';
};

/**
 * Accordion Utility
 *
 * Used for creating a list of expandable content in which only one item of
 * content is expanded at a time.
 *
 * Settings:
 *   itemSelector - Required - [string] - CSS selector for item wrappers
 *   headerSelector - Required - [String] - CSS selector for header elements
 *     that will be used to open/close accordion items when clicked
 *   contentSelector - Required - [String] - CSS selector for contents elements
 *     that will be hidden or shown when headers are clicked.
 *   animation - Optional - [Object] - animation settings for expanding/collapsing
 *
 * Usage:
 *   $('.accordion').accordion({
 *     itemSelector: '.accordion__item',
 *     headerSelector: '.accordion__header',
 *     contentSelector: '.accordion__contents'
 *   });
 *
 *  Available Methods:
 *    showItem - Show the specified item and collapse all others.
 *        Ex. $('.selector')[0].accordion.showItem($item);
 *    hideItem - Hide any open item within the accordion.
 *        Ex. $('.selector')[0].accordion.hideItem();
 *    setA11y - Set A11y attributes & structure.
 */

(function ($) {

  // Set plugin method name and defaults
  const pluginName = 'accordion',
      defaults = {
        animation: {
          duration: 450,
          easing: "easeInOutQuart"
        }
      };

  // Plugin constructor
  function Plugin (element, settings) {
    // Set up internals for tracking, just in case.
    this._name = pluginName;
    this._defaults = defaults;
    this.$element = $(element);

    // Use defaults for any unassigned settings.
    this.settings = { ...defaults, ...settings };

    // Set several fixed global settings.
    // `open` class is used for legacy support.
    this.settings.openClasses = 'is-open open';
    this.settings.openSelector = '.is-open, .open';

    // Do initial setup stuff.
    this.init();
  }

  /**
   * Open the specified item in the accordion and manage closing other items.
   *
   * @param {jQuery Object} $item - The specific item to be opened.
   * @param {jQuery Object} $items - All items within the accordion.
   */
   Plugin.prototype.openAccordion = function ($item) {
    const $items = this.$element.find(this.settings.itemSelector),
          $otherItems = $items.not($item);

    // First make sure other open items are closed.
    this.hideItems($otherItems);

    // Then open/close the clicked item.
    if ($item.is(this.settings.openSelector)) {
      this.hideItem($item);
    } else {
      this.showItem($item);
    }

    // Trigger custom event for external scripts to know when an accordion is
    // interacted with.
    this.$element.trigger('accordion:after');
  };

  /**
   * Show the contents of the specified item
   *
   * @param {jQuery Object} $item - The item to show the contents of.
   */
  Plugin.prototype.showItem = function ($item) {
    $item.addClass(this.settings.openClasses);
    $item.find(this.settings.contentSelector).slideDown(this.settings.animation);
    $item.find('button.accordion__title').attr('aria-expanded', 'true');
  };

  /**
   * Hide the contents of the specified item.
   *
   * @param {jQuery Object} $item - The item to hide the contents of.
   */
  Plugin.prototype.hideItem = function ($item) {
    $item.removeClass(this.settings.openClasses);
    $item.find(this.settings.contentSelector).slideUp(this.settings.animation);
    $item.find('button.accordion__title').attr('aria-expanded', 'false');
  };

  /**
   * Hide any open items passed in.
   *
   * @param {jQuery Object} $items - The set of items to close if open.
   */
  Plugin.prototype.hideItems = function ($items) {
    const _this = this;

    $items.each(function (index, item) {
      if ($(item).is(_this.settings.openSelector)) {
        _this.hideItem($(item));
        $(item).find('button.accordion__title').attr('aria-expanded', 'false');
      }
    });
  };

  /**
   * Set up accessibility attributes & structure.
   *
   * @param {jQuery Object} $items - All items within the accordion.
   **/
  Plugin.prototype.setA11y = function ($items) {
    // In order to adhere to A11y guidelines for interactive content and keyboard navigation,
    // we should be using a button element which has native keyboard interaction.
    const isUsingButton = $items.find('button[aria-expanded]').length;
    const $enhanceEl = $items.find(':not([aria-expanded])');
    if (!isUsingButton) {
      $enhanceEl.find('.accordion__title').replaceWith(function() {
        // In the case accordion__title element has child elements, remove inner
        // headline and update divs to spans. Inside <button>: Only phrasing content
        // allowed. This also avoids odd headline structure when we are
        // wrapping the button with a headline programmatically.
        if ($(this).find('h1, h2, h3, h4, h5, h6, p').length > 0) {
          $(this).find('h1, h2, h3, h4, h5, h6, p').replaceWith(function() {
            return $(this).text();
          });
        }
        if ($(this).find('div').length > 0)  {
          $(this).find('div').each(function() {
            // Keep attributes if there are any.
            const attr = $(this).prop("attributes");
            const span = $('<span></span>');
            if (attr) {
              $.each(attr, function() {
                span.attr(this.name, this.value);
              });
              $(this).replaceWith(span.html($(this).html()));
            }
            // Preserve formatting with display-block when we
            // change divs to spans in the accordion button.
            span.addClass('display-block');
          })
        }
        return `<button class="accordion__title">${ $(this).html() }</button>`
      });
    }

    const _this = this;
    const $accordionButton = $('button.accordion__title');
    const getNextHeadingLevel = Components.utils.setNextHeadingLevelA11y($('.accordion'));
    $items.each(function (index){
      // ARIA attributes for screen readers. Set a unique ID for each accordion__item.
      // Set aria-labelledby to give the role="region" an accessible name.
      const randomID = Math.floor(Math.random()  * (10000 - index) + index);
      const panelID = 'panel-' + randomID;
      const buttonID = 'accordion-' + randomID;
      $(this).find($accordionButton).attr({ 'id': buttonID, 'aria-controls':panelID, 'aria-expanded':'false' });
      $(this).find('.accordion__content-wrapper').attr({ 'id':panelID, 'aria-labelledby':buttonID, 'role':'region' });
      // If the accordion is open by default, set aria-expanded="true".
      $(this).filter(_this.settings.openSelector).find($accordionButton).attr('aria-expanded', 'true');

      // The following sets the button wrapper to the next logical heading level
      // to align with A11y best practices for document structure.
      const newHeadingEl = document.createElement(`${getNextHeadingLevel}`);
      // The accordion headers should be styled as H5.
      newHeadingEl.classList.add('heading--h5');
      // Insert new heading as parent of the button.
      $(this).find('.accordion__title-wrapper').prepend(newHeadingEl);
      $(this).find($accordionButton).prependTo(newHeadingEl);
    })
  }

  // Initial setup tasks
  Plugin.prototype.init = function () {
    const _this = this,
        $items = this.$element.find(_this.settings.itemSelector),
        hash = window.location.hash;

    // Setup accessibility attributes and structure.
    this.setA11y($items);

    // Initially hide contents of all items, except those specified as open by
    // default in the markup.
    $items.not(_this.settings.openSelector).find(_this.settings.contentSelector).hide();

    // Handle showing an accordion item when its heading is clicked.
    this.$element.find(_this.settings.headerSelector).on('click.accordion', function (e) {
      if ($(e.target).is('a')) {
        // If the click is happening on a link inside the header,
        // do not open accordion, open link instead.
        return true;
      }
      _this.openAccordion($(this).closest(_this.settings.itemSelector));
      e.preventDefault();
    });

    // Expand accordion items when linked to with a hash.
    if ($(hash).length && _this.$element.find($(hash)).length) {
      const $item = $(hash).is(_this.settings.itemSelector) ? $(hash) : $(hash).closest(_this.settings.itemSelector);

      _this.openAccordion($item);
    }

  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (settings) {
    return this.each(function initPlugin() {
      let plugin;

      if (!$.data(this, 'plugin_' + pluginName)) {
        plugin = new Plugin(this, settings);
        $.data(this, 'plugin_' + pluginName, plugin);

        // Expose the plugin so methods can be called externally
        // E.g.: element.accordion.openAccordion();
        this.accordion = plugin;
      }
    });
  };
})(jQuery);

/**
 * Content Flyout utility.
 *
 * Set up a content region that is hidden by default and "flies out" from the
 * right side of the page when a trigger is clicked.
 *
 * Options:
 *   triggers - Required - [jQuery Object] - element(s) to be used as a trigger
 *   slideout - Required - [jQuery Object] - element to slide off screen when content flys in
 *   contents - Optional - [jQuery Object] - element(s) to use as content wrapper
 *   closeLinks - Optional - [jQuery Object] - element(s) to be used to trigger colsing flyout
 *   scroll - Optional - [boolean] - whether the page should scroll up to the flyout content if needed
 *   stayOnTop - Optional - [boolean] - Whether the flyout content should go ontop rather than push slideout content
 *   animation - Optional - [object] - animation settings for expanding/collapsing
 *
 * Usage:
 *  $('.flyout-content-wrapper').contentFlyout({
 *    triggers: $('.triggers-selector')
 *  });
 */

(function ($) {

  // Set plugin method name and defaults
  var pluginName = 'contentFlyout',
      defaults = {
        animation: {
          duration: 1000,
          easing: "easeInOutQuart"
        },
        scroll: true,
        stayOnTop: false
      };


  // plugin constructor
  function Plugin (element, options) {
    // Set up internals for tracking, just in case.
    this._name = pluginName;
    this._defaults = defaults;
    this.element = $(element);

    // Use the init options.
    this.options = $.extend({}, defaults, options);

    // Do it now.
    this.init();

    // Fly out content automatically if there's a matching hash in the URL.
    this.autoReveal();
  }

  /**
   * Show the target content based on the specified $trigger
   *
   * @param {jQuery Object} $trigger - The trigger link corresponding to the
   * content to be displayed
   * @param {Object} settings - Additional options to override defaults including:
   *          {Object} animation - overrides to the default animation settings
   *          {Boolean} scroll - Whether or not to scroll to the conent
   *          {Boolean} scrollDown - Whether or not clicking this trigger should
   *            scroll down to the top of the flyout content container.
   */
  Plugin.prototype.showContent = function(trigger, settings) {
    var data = $(trigger).length ? $(trigger).data() : {},
        $target = data.flyoutTarget ? $('#' + data.flyoutTarget) : this.element,
        $parent = $target.offsetParent(),
        $slideout = $parent.find(this.options.slideout),
        href = $(trigger).length ? $(trigger).attr('href') : '',
        parentPadding = $parent.outerHeight() - $parent.height(),
        offset = $('.sticky-wrapper').outerHeight(true),
        distance = Math.round($target.outerWidth() / $parent.width() * 100),
        $moving,
        defaultSettings = {
          scroll: this.options.scroll,
          scrollDown: data.flyoutScrollDown,
          animation: this.options.animation
        };

    // Merge settings with defaults.
    settings = $.extend({}, defaultSettings, settings);

    $target.data('flyoutState', 'open');

    // Adjust height of parent, as long as it's not the body or html element.
    if (!$parent.is('body, html')) {
      $parent.animate({
        height: $target.outerHeight(true) - parentPadding,
      }, settings.animation);
    }

    if (this.options.stayOnTop) {
      $moving = $target;
    }
    else {
      $moving = $target.add($slideout);
    }

    // Slide out Animation is controlled by css animation.
    $target.add($slideout).addClass('is-open');

    if (settings.scroll) {
      Components.utils.smoothScrollTop($parent, settings.animation.duration, offset, !settings.scrollDown);
    }

    // Push the current state to the URL
    if ((href.indexOf('#') === 0) && (href.length > 1) && (history.replaceState)) {
      history.replaceState(undefined, undefined, href);
    }
  };

  // Hide the target content
  Plugin.prototype.hideContent = function(trigger) {
    var data = $(trigger).length ? $(trigger).data() : {},
        $target = data.flyoutTarget ? $('#' + data.flyoutTarget) : this.element,
        $parent = $target.offsetParent(),
        $slideout = $parent.find(this.options.slideout),
        slideoutHeight = $slideout.outerHeight(true),
        distance = Math.round($target.outerWidth() / $parent.width() * 100),
        $moving;

    $target.data('flyoutState', 'closed');

    // Adjust height of parent, as long as it's not the body or html element.
    if (!$parent.is('body, html')) {
      $parent.animate({
        height: slideoutHeight,
      }, this.options.animation);
    }

    if (this.options.stayOnTop) {
      $moving = $target;
    }
    else {
      $moving = $target.add($slideout);
    }

    // Slide out Animation is controlled by css animation.
    $target.add($slideout).removeClass('is-open');

    // Reset height of $parent to inherit in case of screen resizing that would
    // need to adjust the height.
    setTimeout(function() {
      $parent.css('height', 'inherit');
    }, this.options.animation.duration + 1);

    // Remove the current state from the URL
    if ((window.location.hash.indexOf('#') === 0) && (history.replaceState)) {
      history.replaceState(undefined, undefined, window.location.pathname);
    }
  };

  // Automatically reveal content when the ID of the container is in the URL
  // hash.
  Plugin.prototype.autoReveal = function() {
    var hash = window.location.hash,
        $trigger;

    // Avoid colliding with flyout form behavior.
    if (hash === "#form") {
      return;
    }

    // If the hash exists (e.g. #something) and it matches using jQuery selection.
    if (hash.length > 1 && this.element.is(hash)) {
      $trigger = $(hash).data('flyoutTrigger');

      // Prevent scrolling to the anchor...
      setTimeout(function() {
        window.scrollTo(0, 0);
      }, 1);

      this.showContent($trigger, {
        animation: {duration: 0},
        scroll: true,
        scrollDown: true
      });
    }
  };

  // Hand-full of setup tasks
  Plugin.prototype.init = function () {
    var _this = this,
        $triggers = $();

    // Link content back to its corresponding trigger
    _this.options.triggers.each(function(index, el) {
      var $trigger = $(this),
          targetId = $trigger.data('flyoutTarget'),
          $target = $('#' + targetId);

      // Only pay attention if the target is the correct one.
      if (_this.element.is($target)) {
        $triggers = $triggers.add($trigger);
        $target.data('flyoutTrigger', $trigger);

        // Set the trigger link's href if it isn't already set, excluding "#".
        if ($trigger.attr('href').length <= 1) {
          $trigger.attr('href', '#' + targetId);
        }
      }
    });

    if ($triggers.length && _this.element.length) {
      // Add flyout-state data
      _this.element.data('flyoutState', 'closed');

      // Set the relative parent to hide overflow
      _this.element.each(function(index, el) {
        var $offsetParent = $(this).offsetParent();
        // The following segment looks odd but it is to avoid using particularly
        // slow jQuery methods including .show()
        this.style.display = '';

        if (!$offsetParent.is('body, html')) {
          $offsetParent.css('overflow', 'hidden');
        }
        else {
          $offsetParent.css('overflow-x', 'hidden');
        }
      });

      // Handle opening the flyout when a trigger is clicked.
      $triggers.on('click.flyout', function(e) {
        var trigger = this,
            $target = $('#' + $(trigger).data('flyoutTarget')),
            state = $target.data('flyoutState');

        // Set the speed of the animation to be consistent regardless of viewport.
        _this.options.animation.duration = Components.utils.animationDuration($target.outerWidth(), 1500);

        if (state === 'closed') {
          setTimeout(function() {
            _this.showContent(trigger);
          }, 1);
        }
        e.preventDefault();
      });

      // Handle closing the flyout when a close link is clicked.
      _this.options.closeLinks.on('click.flyout', function(e) {
        var $parent = $(this).closest(_this.element),
            state = $parent.data('flyoutState');

        // Double-check that the flyout is open and it's the correct flyout.
        if (_this.element.is($parent) && state === 'open') {
          _this.hideContent($parent.data('flyoutTrigger'));
        }
        e.preventDefault();
      });
    }
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (options) {
    return this.each(function initPlugin() {
      var plugin = new Plugin(this, options);
      // Allow the plugin to be instantiated more than once. Event handlers
      // will be re-bound to avoid issues.
      $.data(this, 'plugin_' + pluginName, plugin);

      // Expose the plugin so methods can be called externally
      //   Ex. $(element).contentFlyout.showContent();
      this.contentFlyout = plugin;
    });
  };
})(jQuery);

/**
 * Content Reveal utility.
 *
 * Set a wrapper around content as a revealable region. Assign a "trigger"
 * element as the toggle to expand and collapse the content region.
 *
 * Options:
 *    triggers - Required - [jQuery Object] - element(s) to be used as a trigger
 *    contents - Optional - [jQuery Object] - element(s) to use as content wrapper
 *    closeLink - Optional - [boolean] - whether a close link should be added
 *    animation - Optional - [object] - animation settings for expanding/collapsing
 *
 * Usage:
 *    $('.content-wrapper').contentReveal({
 *      triggers: $('.triggers-selector')
 *    });
 *
 * Available Methods:
 *    showContent - Trigger a particular reveal content wrapper to show
 *        Ex. $('.content-wrapper')[0].contentReveal.showContent();
 *    hideContent - Trigger a particular reveal content wrapper to hide
 *        Ex. $('.content-wrapper')[0].contentReveal.hideContent();
 */

(function ($) {

  // Set plugin method name and defaults
  var pluginName = 'contentReveal',
      requestBrightcove = $.Deferred(),
      defaults = {
        animation: {
          duration: 1000,
          easing: "easeInOutQuart"
        },
        closeLink: true
      };

  // plugin constructor
  function Plugin (element, options) {
    // Set up internals for tracking, just in case.
    this._name = pluginName;
    this._defaults = defaults;
    this.element = $(element);

    // Use the init options.
    this.options = $.extend({}, defaults, options);

    // Do setup stuff.
    this.init();

    // Reveal content automatically if there's a matching hash in the URL.
    this.autoReveal();
  }

  /**
   * Reveal content based ont he passed in trigger link.
   *
   * @param {jQuery Object} $trigger - The link corresponding to the content to display
   * @param {Object} settings - Additional options to override defaults including:
   *          {Object} animation - overrides to the default animation settings
   *          {String} scrollBehavior - how scrolling should be handled
   *          {String} hideText - Text to swap into the trigger link while the
   *            reveal is in the open state
   *          {String} media - Type of media in the content container if special
   *            handling is needed
   *          {Boolean} expandToggle - Whether the trigger link has an expand icon
   */
  Plugin.prototype.showContent = function(trigger, settings) {
    var $trigger = $(trigger).length ? $(trigger) : this.element.data('reveal-trigger'),
        href = $trigger.attr('href'),
        data = $trigger.data(),
        $target = $('#' + data.revealTarget),
        $curtain = $('#' + data.revealCurtain),
        scrollOffset = $('.sticky-wrapper .stuck').outerHeight(true),
        defaultSettings = {
          animation: this.options.animation,
          scrollBehavior: data.revealScroll,
          hideText: data.revealHideText,
          media: data.revealMedia,
          expandToggle: data.revealExpandToggle
        },
        $scrollTarget,
        videoElement,
        player;

    // Merge settings with defaults.
    settings = $.extend({}, defaultSettings, settings);

    $target.add($trigger).data('revealState', 'open').addClass('is-open');
    if (settings.hideText) {
      $trigger.text(settings.hideText);
    }

    // Swap content.
    // NOTE: Video players break via display:none, thus custom function.
    $curtain.slideHeight('up', settings.animation);
    $target.slideHeight('down', settings.animation);

    if (settings.media === 'video') {
      videoElement = $target.find('.video-js')[0];
      // Ensure Brightcove is loaded.
      requestBrightcove.then(function () {
        // Get the player instance.
        player = Plugin.getBrightcovePlayer(videoElement);

        // Ensure player is ready before calling .play()
        player.ready(function () {
          player.play();
        });
      });
    }

    // Scroll when reveal is clicked open.
    if (settings.scrollBehavior) {
      switch (settings.scrollBehavior) {
        case 'trigger':
          $scrollTarget = $trigger;
          break;
        case 'target':
          $scrollTarget = $target;
          break;
        default:
          $scrollTarget = $('#' + settings.scrollBehavior);
          break;
      }
      Components.utils.smoothScrollTop($scrollTarget, settings.animation.duration, scrollOffset, false);
    }
    else if ($curtain.length) {
      // Use curtain for scroll.
      Components.utils.smoothScrollTop($curtain, settings.animation.duration, scrollOffset, true);
    }

    // Special expand icon handling
    if (settings.expandToggle) {
      $trigger.addClass('link--collapse').removeClass('link--expand');
    }

    // Push the current state to the URL
    if ((href.indexOf('#') === 0) && (href.length > 1) && (history.replaceState)) {
      history.replaceState(undefined, undefined, href);
    }
  };

  // Hide the target content
  Plugin.prototype.hideContent = function(trigger) {
    var $trigger = $(trigger).length ? $(trigger) : this.element.data('reveal-trigger'),
        data = $trigger.data(),
        $target = $('#' + data.revealTarget),
        $curtain = $('#' + data.revealCurtain),
        showText = data.revealShowText,
        media = data.revealMedia,
        expandToggle = data.revealExpandToggle,
        player;

    if (typeof showText !== 'undefined') {
      $trigger.text(showText);
    }

    // Swap content.
    $target.slideHeight('up', this.options.animation);
    $curtain.slideHeight('down', this.options.animation);

    // Remove the is-open modifier to reflect the state.
    setTimeout(function () {
      $target.add($trigger).data('revealState', 'closed').removeClass('is-open');
    }, this.options.animation.duration);

    if (media === 'video') {
      player = videojs($target.find('.video-js')[0]);
      player.pause();
    }

    // Special expand icon handling
    if (expandToggle) {
      $trigger.addClass('link--expand').removeClass('link--collapse');
    }

    // Remove the current state from the URL
    if ((window.location.hash.indexOf('#') === 0) && (history.replaceState)) {
      history.replaceState(undefined, undefined, window.location.pathname);
    }
  };

  // Automatically reveal content when the ID of the container is in the URL
  // hash.
  Plugin.prototype.autoReveal = function () {
    var hash = window.location.hash,
        $trigger;

    // If the hash exists (e.g. #something) and it matches using jQuery selection.
    if (hash.length > 1 && this.element.is(hash)) {
      $trigger = $(hash).data('revealTrigger');

      this.showContent($trigger, {
        animation: {duration:0},
        scrollBehavior : "target"
      });
    }
  };

  // Hand-full of setup tasks
  Plugin.prototype.init = function () {
    var _this = this,
        $triggers = $();

    // Link content back to its corresponding trigger
    _this.options.triggers.each(function () {
      var $trigger = $(this),
          targetId = $trigger.data('revealTarget'),
          $target = $('#' + targetId);

      // Only pay attention if the target is the correct one.
      if (_this.element.is($target)) {
        $triggers = $triggers.add($trigger);
        $target.data('revealTrigger', $trigger);
        $target.data('revealState', 'closed');

        // Set the trigger link's href if it isn't already set, excluding "#".
        if ($trigger.attr('href').length <= 1) {
          $trigger.attr('href', '#' + targetId);
        }
      }
    });

    if ($triggers.length && _this.element.length) {
      // Add reveal-state data
      _this.element.data('revealState', 'closed');

      // Add a close icon to each content continer
      if (_this.options.closeLink) {
        _this.element.prepend($('<a href="#" class="reveal__close" href="#"><i class="icon icon--close-window-style2"></i></a>'));
      }

      $triggers.each(function () {
        var $trigger = $(this),
            $target = $('#' + $trigger.data('revealTarget')),
            showText = $trigger.text();

        // Link content back to it's corresponding trigger
        $target.data('revealTrigger', $trigger);

        // Special handling for links with an expand icon.
        if ($trigger.hasClass('link--expand')) {
          $trigger.data('revealExpandToggle', true);
        }

        // Save original trigger text
        if (typeof $trigger.data('revealHideText') !== 'undefined') {
          $triggers.data('revealShowText', showText);
        }

        // Remove close link if the data attribute is set to false.
        if (_this.options.closeLink && $trigger.data('revealCloseLink') === false) {
          $target.find('.reveal__close').remove();
        }
      });

      $triggers.on('click.reveal', function(e) {
        var state = _this.element.data('revealState');

        if (state === 'closed') {
          _this.showContent(this);
        } else if (state == 'open') {
          _this.hideContent(this);
        }
        e.preventDefault();
      });

      $('.reveal__close').on('click.reveal', function(e) {
        var $parent = $(this).closest(_this.element),
            state = $parent.data('revealState');

        // Double-check that the flyout is open and it's the correct flyout.
        if (_this.element.is($parent) && state === 'open') {
          _this.hideContent($parent.data('revealTrigger'));
        }
        e.preventDefault();
      });
    }
  };


  // Gets/creates a Brightcove player instance. Used for delaying player instantiation.
  // Returns videojs player object.
  Plugin.getBrightcovePlayer = function (videoElement) {
    var $video = $(videoElement);
    var data = $(videoElement).data();

    // Has reveal attributes and not yet initialized?
    if (data.revealAccount && data.revealPlayer && data.revealVideoId && !data.revealInitialized) {
      // Copy the attributes over for `bc()` to work.
      $video.attr({
        'data-account': data.revealAccount,
        'data-player': data.revealPlayer,
        'data-video-id': data.revealVideoId
      });
      // Call bc to initialize this as a Brightcove video.
      bc(videoElement);
      // Flag this as initialized.
      data.revealInitialized = true;
    }

    return videojs(videoElement);
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (options) {
    return this.each(function initPlugin() {
      var plugin = new Plugin(this, options);
      // Allow the plugin to be instantiated more than once. Event handlers
      // will be re-bound to avoid issues.
      $.data(this, 'plugin_' + pluginName, plugin);

      // Expose the plugin so methods can be called externally
      //   Ex. $(element).contentReveal.showContent();
      this.contentReveal = plugin;
    });
  };

  // Check if Brightcove is available and resolve if found.
  function resolveBrightcove() {
    if (typeof window.bc === 'function' && window.bc.VERSION) {
      requestBrightcove.resolve(window.bc);
    }
  }

  // Try checking for synchronously loaded Brightcove.
  resolveBrightcove();
  // Wait for async Brightcove script and resolve if found.
  $(document).one('brightcove:loaded', resolveBrightcove);

})(jQuery);

'use strict';

/**
 * jQuery Dynamic Select Filters
 *
 * Given a set of input radio options, generate a corresponding select list per
 * option group and binds change events so that using the select triggers the
 * original option inputs, which may now be hidden.
 *
 * The javascript init, with options thrown in:
 *

  $('.filter-set').dynamicSelectFilters({
    container: '.mobile-filter-set',
    groupHeading: '.filter-set__heading',
    onCreateSelectCallback: function () {
      // 'this' is the jQuery wrapped select element, created per group set.
      this.wrap('<div class="form-field__wrapper"><div class="form__select"></div></div>');

      // Perform additional event bindings as needed.
      this.on('click.namespace', function myCoolEvent(e) {
        doMyThings();
      });
    }
  });

 */
(function ($) {
  // Set plugin method name and defaults
  var pluginName = 'dynamicSelectFilters',
      defaults = {
        // A DOM selector of the container to place the dynamic <select> elements.
        // If not defined, one will be generated and placed before the first
        // option group found.
        container: false,
        // An optional DOM selector to provide a default option in the select
        // element. Should be located inside the grouping DOM element.
        groupHeading: '',
        // Callback function after each select is created. Passes in the newly
        // created select jQuery element to perform any additional modifications.
        onCreateSelectCallback: null
      };

  // plugin constructor
  function Plugin (element, options) {
    var $element = $(element);

    // Set up internals for tracking, just in case.
    this._name = pluginName;
    this._defaults = defaults;
    this._element = $element;

    // Use the init options.
    this.options = $.extend(true, defaults, options);

    // Do it now.
    this.init();
  }

  Plugin.prototype.init = function () {
    var _options = this.options,
        $radioGroups = this._element,
        $selectContainer = $radioGroups.find(_options.container);

    if (!$radioGroups.length) {
      return;
    }

    // If no container for the select is defined, add one.
    if (!$selectContainer.length) {
      $radioGroups.eq(0).before('<div class="dynamic-select-container"></div>');
      $selectContainer = $radioGroups.eq(0).prev('.dynamic-select-container');
    }

    $radioGroups.each(function initSelectDuplication() {
      var $this = $(this),
          // Grouping label, generated as a disabled option within the select to
          // act as a label.
          groupHeading = $this.find(_options.groupHeading),
          $input = $this.find('input[type="radio"]'),
          $label = $this.find('label'),
          $select = $('<select>'),
          selectOptions = '';

      if (!$input.length) {
        return;
      }

      // If given a groupHeading element, use it to create a placeholder-esque
      // option for the current <select>
      if (groupHeading.length) {
        selectOptions = '<option class="select-placeholder" disabled selected>' + groupHeading.text().trim().replace(/\:$/, '') + '</option>';
      }

      // Continue building out the select options using all the radio/checkbox inputs.
      $input.each(function buildSelectOptions() {
        var $this = $(this),
            $label = $this.next('label'),
            triggerElement = '#' + $this.attr('id').trim(),
            isSelected = ($this.is(':checked')) ? 'selected' : '';

        // Let the option value be the input element to trigger, by DOM id.
        selectOptions += '<option value="' + triggerElement + '" ' + isSelected + '>' + $label.text() + '</option>';

        // Sync the select state when the option input is used.
        $this.on('change.dynamicfilter', function twoWayValueBind() {
          $select.find('option[value="' + triggerElement + '"]').prop('selected', true);
        });
      });

      // Flesh out the select, and enact bindings.
      $select.html(selectOptions)
        .on('change.dynamicfilter', function bindDynamicSelectActions() {
          var $triggerEl = $($(this).val());

          $triggerEl.prop('checked', true).trigger('change');
        })
        .appendTo($selectContainer);

        // Apply any per instance callbacks.
        if (typeof _options.onCreateSelectCallback === 'function') {
          _options.onCreateSelectCallback.call($select);
        }
    });
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (options) {
    return this.each(function initPlugin() {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName,
        new Plugin(this, options));
      }
    });
  };
})(jQuery);

"use strict";

/**
 * jQuery Float Labels
 *
 * A simple plugin to enable the floating label pattern. It makes no attempt to
 * control any interactions of the label within js. It just binds to events as
 * needed and triggers configurable CSS classes.
 *
 * The jQuery method is called on the wrapper element that contains both the field
 * and the label. It might look like:
 *
 * <div class="field__wrapper">
 *   <label class="field__label" for="this-field">My Super Label</label>
 *   <input name="this-field">
 * </div>
 *
 * The javascript init, with options thrown in:
 * $('.field__wrapper').floatLabel({
 *   activeClass: 'activated',
 *   focusClass: 'zenified'
 * });
 */
(function ($) {
  // Set plugin method name and defaults
  var pluginName = 'floatLabel',
      defaults = {
        // In case you want to preserve labels as visible for no js, or old
        // IE users.
        wrapperInitClass: 'has-float-label',
        // For a custom label selector, if you have multiple labels, for some
        // reason.
        labelSelector: false,
        // Class given to label when its field has a non-null value. Toggled
        // when the value is empty / falsy.
        activeClass: 'is-active',
        // Class given to input when it has an empty value.
        emptyClass: 'is-empty',
        // Class given to label when its field is focused. Toggled when it
        // loses focus.
        focusClass: 'has-focus',
        // Class for lack of proper placeholder support.
        badSupportClass: 'is-msie'
      },
      // Detect misbehaved user agents.
      hasBadPlaceholderSupport = Boolean(window.navigator.userAgent.match(/(MSIE |Trident\/)/));

  // plugin constructor
  function Plugin (element, options) {
    var $element = $(element);

    // Set up internals for tracking, just in case.
    this._name = pluginName;
    this._defaults = defaults;
    this._element = $element;

    // Use the init options.
    this.options = $.extend(true, defaults, options);

    // Set up a couple of internals to keep track of input and label.
    this._wrapper = $element;
    this._input = this._findInput($element);
    this._label = this._findLabel($element);

    // Do it now.
    this.init();
  }

  // Utility: find a input that we want to alter the label for.
  Plugin.prototype._findInput = function($el) {
    var $textInputs = $el.find('input, textarea').not('[type="checkbox"], [type="radio"]');
    // The regular text input types.
    if ($textInputs.length) {
      return $textInputs;
    }
    // Try for select elements.
    else {
      return $el.find('select');
    }
  };

  // Utility: find a label in the field wrapper element.
  Plugin.prototype._findLabel = function(el) {
    // If a custom selector is provided
    if (this.options.labelSelector) {
      return $(el).find(this.options.labelSelector);
    }

    // Just try a label element.
    return $(el).find('label');
  };

  Plugin.prototype._checkValue = function () {
    var isEmpty = this._input.val() === '' || this._input.val() === '_none';

    // Apply the correct classes based on value emptiness.
    this._input.toggleClass(this.options.emptyClass, isEmpty);
    this._label.toggleClass(this.options.activeClass, !isEmpty);

    // Apply the bad placeholder support classes if needed.
    this._label.add(this._input)
      .toggleClass(this.options.badSupportClass, hasBadPlaceholderSupport);
  };

  Plugin.prototype._onKeyUp = function () {
    this._checkValue();
  };

  Plugin.prototype._onFocus = function () {
    this._label.addClass(this.options.focusClass);
    this._onKeyUp();
  };

  Plugin.prototype._onBlur = function () {
    this._label.removeClass(this.options.focusClass);
    this._onKeyUp();
  };

  Plugin.prototype.init = function () {
    // Mark the element as having been init'ed.
    this._element.addClass(this.options.wrapperInitClass);

    // Check value for initial active class.
    this._checkValue();

    // Event bindings to the input element with floatLabels namespace.
    this._input
      .off('keyup.floatLabels change.floatLabels')
      .on('keyup.floatLabels change.floatLabels', $.proxy(this._onKeyUp, this));
    this._input
      .off('blur.floatLabels')
      .on('blur.floatLabels', $.proxy(this._onBlur, this));
    this._input
      .off('focus.floatLabels')
      .on('focus.floatLabels', $.proxy(this._onFocus, this));
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (options) {
    return this.each(function initPlugin() {
      // Allow the plugin to be instantiated more than once. Event handlers
      // will be re-bound to avoid issues.
      $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
    });
  };
})(jQuery);

/**
 * A re-implementation of jQuery's slideDown() and slideUp() that animates the
 *  height of an element without requiring the use of display: none;
 *
 *  Helpful when needing to hide a video player while maintaining control via an
 *  API.
 *
 *  This function enforces "overflow: hidden" in order to work properly.
 *  To hide the element by default, set "height: 0" in CSS as well.
 */

(function ($) {

  $.fn.slideHeight = function (direction, options) {
    var $el = $(this);

    options = options || {duration: 400, easing: 'swing'};

    if (direction === 'down') {
      var $elClone = $el.clone()
          // Find all :checked elements (checkboxes, radio buttons, and options of select elements).
          .find(':checked')
          // Remove name attributes from checked elements to prevent the cloned source
          // elements from getting unchecked.
          .removeAttr('name')
          // End the current selection and return to the cloned elements selection, for chaining.
          .end()
          .css({'height': 'auto'}).appendTo($el.parent()),
          elHeight = $elClone.outerHeight(true);

      // Removing clone needed for calculating height.
      $elClone.remove();

      $el.animate({
          height: elHeight
        },
        options.duration,
        options.easing,
        function () {
          // Reset the height to auto to ensure the height remains accurate on viewport resizing
          $el.css({
            height: 'auto',
            overflow: 'inherit'
          });
        }
      );
    }

    if (direction === 'up') {
      // Enforce height zero.
      $el.css('overflow', 'hidden');

      $el.animate({
        height: 0
      }, options);
    }

    return this;
  };

})(jQuery);

/**
 * Tabs content utility
 *
 * Create interactive tabs that switch between different visible content when
 * tabs are clicked.
 *
 * Options:
 *   tabLinks - Required - [jQuery Object] - element(s) to be used as a trigger
 *   contents - Required - [jQuery Object] - element(s) to use as content wrapper
 *   triggers - Optional - [jQuery Object] - additional elements (other than tabs)
 *     used for triggering the display of specific tabs
 *   animation - Optional - [object] - animation settings for expanding/collapsing
 *
 * Usage:
 *   $('.tabs-wrapper-selector').tabs({
 *     tabLinks: $('.tab-links-selector'),
 *     contents: $('.tab-contents-wrapper-selector'),
 *     triggers: $('.tab-triggers-selector')
 *   });
 */

(function ($) {

  // Set plugin method name and defaults
  var pluginName = 'tabs',
      defaults = {
        animation: {
          duration: 0,
          easing: null
        }
      };

  // plugin constructor
  function Plugin (element, options) {
    // Set up internals for tracking, just in case.
    this._name = pluginName;
    this._defaults = defaults;
    this.element = $(element);

    // Use the init options.
    this.options = $.extend({}, defaults, options);

    // Limit tabLinks and contents down to only the set within this instance.
    this.options.tabLinks = this.element.find(this.options.tabLinks).add(this.element.find(this.options.triggers));
    this.options.contents = this.element.find(this.options.contents);

    // Do setup stuff.
    this.init();

    // Display tab automatically if there's a matching hash in the URL.
    if (window.location.hash.length) {
      this.autoReveal();
    }
  }

  /**
   * Brings a tab into view based on the corresponding tab link passed.
   *
   * @param {jQuery Object} $link - The link corresponding to the tab to display
   * @param {Object} settings - Additional options to override defaults including:
   *          {Object} animation - overrides to the default animation settings
   *          {String} scrollBehavior - how scrolling should be handled
   */
  Plugin.prototype.showTab = function($link, settings) {
    var $content = $('#' + $link.data('tab-content')),
        $previousContent = this.options.contents.filter('.is-active'),
        previousContentHeight = $previousContent.outerHeight(true),
        $previousLink = this.options.tabLinks.filter('.is-active'),
        $flyoutContainer = $content.closest('.flyout__content'),
        href = $link.attr('href'),
        contentHeight = $content.outerHeight(true),
        scrollOffset = $('.sticky-wrapper .stuck').outerHeight(true),
        defaultSettings = {
          animation: {
            duration: !isNaN(this.element.data('tabs-duration')) ? this.element.data('tabs-duration') : this.options.animation.duration,
            easing: this.options.animation.easing
          },
          scrollBehavior: this.element.data('tabs-scroll')
        },
        $scrollTarget,
        $parent,
        parentPadding,
        flyoutHeight,
        heightChange;

    // Merge settings with defaults.
    settings = $.extend({}, defaultSettings, settings);

    if (!$link.hasClass('is-active')) {
      // Manage active class
      this.options.tabLinks.add(this.element.find(this.options.contents)).removeClass('is-active');
      $link.add($content).addClass('is-active');
      // Update accessibility roles and attributes.
      if ($previousLink.length) {
        $previousLink.removeAttr('aria-selected');
      }
      $link.attr('aria-selected', 'true');

      // Not in flyout? Add an animation complete handler to reset the height.
      if (!$flyoutContainer.length && !settings.animation.complete) {
        settings.animation.complete = function () {
          $(this).css({height: 'auto'});
        };
      }

      // Animate the height transition between tabs
      $content.height(previousContentHeight).animate({
        height: contentHeight
      }, settings.animation);

      // Manage flyout container if tabs are within a flyout
      if ($flyoutContainer.length) {
        $parent = $flyoutContainer.offsetParent();
        parentPadding = $parent.outerHeight() - $parent.height();
        flyoutHeight = $flyoutContainer.outerHeight(true);
        heightChange = contentHeight - previousContentHeight;

        // Adjust height of parent
        if (heightChange <= 0) {
          // If heightChange is negative, set the height to the flyout. This prevents cutting off content.
          $parent.animate({
            height: flyoutHeight
          }, settings.animation);
        } else {
          // If heightChange is positive, subtract parent padding to prevent additional padding.
          $parent.animate({
            height: flyoutHeight - parentPadding + heightChange
          }, settings.animation);
        }
      }
    }

    // Handling scrolling behaviors
    if (settings.scrollBehavior) {
      switch (settings.scrollBehavior) {
        case 'wrapper':
          $scrollTarget = this.element;
          break;
        case 'content':
          $scrollTarget = $content;
          break;
        default:
          $scrollTarget = $('#' + settings.scrollBehavior);
          break;
      }

      Components.utils.smoothScrollTop($scrollTarget, settings.animation.duration, scrollOffset, false);
    }

    // Push the current state to the URL
    if ((href.indexOf('#')) === 0 && (href.length > 1) && (history.replaceState)) {
      history.replaceState(undefined, undefined, href);
    }

    // Trigger an event to listen to from other scripts.
    this.element.trigger(pluginName + '.showTab');
  };

  // Automatically reveal content when the ID of the container is in the URL
  // hash.
  Plugin.prototype.autoReveal = function() {
    var hash = window.location.hash,
        $tabLink = this.options.tabLinks.filter('[href="' + hash + '"]'),
        scroll = "wrapper",
        $flyoutContainer,
        $flyoutTrigger;

    // Make sure the tab link exists and only run if it's within the current
    // tabs wrapper.
    if ($tabLink.length && this.element.find($tabLink).length) {
      $flyoutContainer = $tabLink.closest('.flyout__content');
      scroll = $flyoutContainer.length ? false : "wrapper";

      // Show the correct tab with quick animation.
      this.showTab($tabLink, {
        animation: {duration:100},
        scrollBehavior : scroll
      });

      // If the tab is inside a content flyout, make sure that the
      // content flyout is opened.
      if ($flyoutContainer.length && !$flyoutContainer.hasClass('is-open')) {
        $flyoutTrigger = $flyoutContainer.data('flyout-trigger');
        $flyoutContainer[0].contentFlyout.showContent($flyoutTrigger, {
          animation: {duration: 0},
          scroll: true,
          scrollDown: true
        });
      }
    }
  };

  // Hand-full of setup tasks
  Plugin.prototype.init = function () {
    var _this = this,
        $flyoutContainer = _this.element.find('.flyout__content'),
        $tabList = _this.element.find('.tabs__tab-list, .tabs__tabs');

    // Set up properties for wrapper
    $tabList.attr('role', 'tablist');

    if (_this.options.tabLinks.length && _this.options.contents.length) {

      // Show tabs on click.
      _this.options.tabLinks.on('click.tabs', function(e) {
        e.preventDefault();
        _this.showTab($(this));
      });

      // Set up tab link properties.
      _this.options.tabLinks.each(function(index, el) {
        var tabId = $(el).data('tab-content'),
            fragment = '#' + tabId;

        // Add accessible properties
        $(el).attr('role', 'tab');
        $(el).parent().attr('role', 'presentation');
        // Set an  id for the panel to use as aria-labelledby
        $(el).attr('id', 'tab-link__' + tabId);

        // If we're within a flyout, prefix with the flyout's ID
        if ($flyoutContainer.length) {
          fragment = '#' + $flyoutContainer.attr('id') + '-' + tabId;
        }

        // Set the href for the tab as well as any triggers that target the
        // same content.
        if ($(el).attr('href').indexOf('#') === 0) {
          $(el).attr('href', fragment);
        }
      });

      // Set up panel properties.
      _this.options.contents.each(function (index, el) {
        var id = $(el).attr('id');
        // Add accessible properties
        $(el).attr('role', 'tabpanel');
        $(el).attr('aria-labelledby', 'tab-link__' + id);
      })

      // Handle other triggers displaying
      if (_this.options.triggers) {
        _this.options.triggers.on('click.tabs-trigger', function (e) {
          var $link = _this.options.tabLinks.filter('[data-tab-content="' + $(this).data('tab-content') + '"]'),
              $content = $('#' + $(this).data('tab-content'));

          if ($link.length) {
            // Manage active class
            _this.options.tabLinks.add(_this.options.contents).removeClass('is-active');
            $link.add($content).addClass('is-active');
          }

          // Push the current state to the URL
          if (history.replaceState) {
            history.replaceState(undefined, undefined, $link.attr('href'));
          }

          e.preventDefault();
        });
      }
    }
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (options) {
    return this.each(function initPlugin() {
      var plugin = new Plugin(this, options);
      // Allow the plugin to be instantiated more than once. Event handlers
      // will be re-bound to avoid issues.
      $.data(this, 'plugin_' + pluginName, plugin);

      // Expose the plugin so methods can be called externally
      //   Ex. element.tabs.showTab();
      this.tabs = plugin;
    });
  };
})(jQuery);

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

Components.form = {};

// Closure to rename jQuery to $.
(function ($) {

  Components.form.initFloatLabels = function ($elements) {
    $elements.find('input, select, textarea')
      .not('[type="checkbox"], [type="radio"]')
      .closest('.form-field')
      .floatLabel({
        labelSelector: '.form-field__label'
      });
  };

  $(document).ready(function () {
    Components.form.initFloatLabels($('.has-float-label'));
  });

  $(document).on('initFloatLabels', function (e) {
    Components.form.initFloatLabels($(e.target));
  });

})(jQuery);

/**
 * Fancy Filters interactions
 */
(function ($) {
  // Bind to document ready and custom components:reattach event so this works on
  // dynamically loaded AJAX content.
  $(document).on('ready components:reattach', function (e, context) {
    $('.fancy-filters')
    // Clear any previously bound handlers.
    .off('click.fancy-filters')
    // Handle "Clear Filters" click by unchecking everything.
    .on('click.fancy-filters', '.fancy-filters__clear', function (e) {
      $(e.delegateTarget).find('input:checked').prop('checked', false).change();
      return false;
    });
  });
})(jQuery);

/**
 * Flyout Form component interaction
 * See jquery.contentFlyout.js for details
 */

(function ($) {
  $(document).ready(function() {
    var fragment = 'form',
        $formWrapper = $('.flyout-form'),
        $triggers = $('a[href*="#' + fragment + '"], .flyout-form__trigger'),
        $closeLink = $formWrapper.find('.flyout-form__close'),
        $pageWrapper = $('main');

    // Make sure a flyout form exists before proceding.
    if ($formWrapper.length && $triggers.length) {
      // Append form wrapper to the page wrapper.
      $pageWrapper.append($formWrapper);

      // If a close button doesn't already exist, add it.
      if (!$closeLink.length) {
        $closeLinkWrapper = $('<div class="flyout-form__close-wrapper">');
        $closeLink = $('<button class="flyout-form__close link link--close">Close</button>');
        $closeLinkWrapper.prepend($closeLink);
        $formWrapper.prepend($closeLinkWrapper);
      }

      // Set the reveal target on the triggers (used by contentFlyout plugin).
      $triggers.data('flyoutTarget', fragment);

      // Flyout Magic using contentFlyout plugin
      $formWrapper.contentFlyout({
        triggers: $triggers,
        slideout: $pageWrapper,
        stayOnTop: true,
        closeLinks: $closeLink,
        scroll: false
      });

      // When open, clicking the page wrapper should close the flyout.
      $pageWrapper.on('click.flyout', function(e) {
        if ($(this).hasClass('is-open')) {
          $formWrapper[0].contentFlyout.hideContent();
          e.preventDefault();
        }
      });

      // Stop propagation of click events on the flyout form itself.
      $formWrapper.on('click.flyout', function (e) {
        e.stopPropagation();
      });

      // Show form on load if the ULR contains the fragment.
      if (window.location.hash === '#' + fragment) {
        // Make sure to only "click" the first trigger."
        $formWrapper[0].contentFlyout.showContent();
      }

      // Close form on hitting Escape key
      $(document).keyup(function(e) {
        if (e.keyCode == 27 && $formWrapper.hasClass('is-open')) { // escape key maps to keycode `27`
          $formWrapper[0].contentFlyout.hideContent();
        }
      });

      // Auto-focus on the first field of the form when it's revealed.
      $triggers.on('click.flyout', function(e) {
        // Make sure we're actually listening to a click on the trigger link
        // rather than a JS event trigger.
        if ($(e.currentTarget).is($triggers)) {
          $formWrapper.find('input:visible').first().focus();
        }
      });
    }
  });
}(jQuery));

/**
 * Compact form.
 *
 */
(function ($) {
  $(document).ready(function () {
    var fragment = 'form',
        $form = $('.form-compact'),
        $cta = $form.find('button.form__button.cta'),
        $triggers = $('a[href*="#' + fragment + '"]');

    // Make sure a compact form exists before proceeding.
    if ($form.length && $cta.length) {
      // Show form on load if the URL contains the form fragment.
      if (window.location.hash === '#' + fragment) {
        if (isReveal()) {
          revealForm();
        }
        $form.find('input:visible').first().focus();
      }

      $cta.click(function (e) {
        if (isReveal() && !$form.hasClass('is-open')) {
          revealForm();
          return false;
        }

        // Support hidden form submits.
        $(this.form).find('input[type="submit"]').click();

        return false;
      });

      // Auto-focus on the form field when it's revealed.
      $triggers.on('click', function(e) {
        revealForm();
      });

      /**
       * Returns whether this is a reveal.
       * @returns Boolean
       */
      function isReveal() {
        return $form.hasClass('form-compact--reveal');
      }

      /**
       * Reveal the e-mail only form.
       */
      function revealForm() {
        var ctaText = $cta.val() || $cta.text();

        if (!$form.hasClass('is-open')) {
          $form.toggleClass('is-open');
          $cta.text(ctaText);
        }

        $form.find('input:visible').first().focus();
      }
    }
  });
})(jQuery);

/**
 * Responsive filters interaction
 *
 * See jquery.dynamicSelectFilters.js
 */
(function ($) {
  // Bind to document ready and custom components:reattach event so this works on
  // dynamically loaded AJAX content.
  $(document).on('ready components:reattach', function (e, context) {
    $('.responsive-filter').dynamicSelectFilters({
      container: '.responsive-filter__select',
      groupHeading: '.responsive-filter__heading',
      onCreateSelectCallback: function () {
        // 'this' is the jQuery wrapped select element, created per group set.
        this.wrap('<div class="form__select"></div>');
      }
    });
  });
})(jQuery);

/**
 * Search Facet behaviors.
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a base for this module's data and functions.
Components.searchFacet = {
  collapsedClass: 'coveo-facet-collapsed'
};

// Closure to extend behavior, provide privacy and state.
(function (component, $) {

  /**
   * DOM-ready callback.
   *
   * @param {Object} $
   *   jQuery
   */
  component.ready = function ($) {
    // Close open search facets when clicking outside of one, or when ESC key is pressed.
    $(document).on('click.searchFacet', function (e) {
      component.closeAll();
    })
    // Also, close on Escape key.
    .on('keydown', function (e) {
      if (e.keyCode === 27) {
        component.closeAll();
      }
    });

    // Initialize search facet components.
    $('.search-facet').on('click.searchFacet', '.search-facet__header, .coveo-facet-header', function (e) {
      var element = e.delegateTarget,
          isCollapsed = $(element).hasClass(component.collapsedClass);

      // Close any other open facets.
      component.closeAll({$except: $(element)});

      // Using Coveo JS Framework.
      // Related: https://coveo.github.io/search-ui/components/facet.html#expand
      if (element.CoveoFacet) {
        if (isCollapsed) {
          element.CoveoFacet.expand();
        }
        else {
          element.CoveoFacet.collapse();
        }
      }
      // Otherwise, just use the class.
      else {
        $(element).toggleClass(component.collapsedClass)
      }

    });

    // Stop propagation of clicks upward to the document to prevent closing.
    $('.search-facet').on('click.searchFacet', function (e) {
      e.stopPropagation();
    });
  };

  /**
   * Closes all open CoveoFacet components.
   *
   * @param {Object} options
   *   options.$except  Exclude a given jQuery selection — uses .not()
   */
  component.closeAll = function (options) {
    var $closeFacets = $('.search-facet');

    if (options && options.$except) {
      $closeFacets = $closeFacets.not(options.$except);
    }

    $closeFacets.each(function () {
      // Using Coveo JS Framework.
      // Related: https://coveo.github.io/search-ui/components/facet.html#collapse
      if (this.CoveoFacet) {
        this.CoveoFacet.collapse();
      }
      // Otherwise, just use the class.
      else {
        $(this).addClass(component.collapsedClass);
      }
    });
  };

})(Components.searchFacet, jQuery);

// Attach our DOM-ready callback.
jQuery(Components.searchFacet.ready);

/**
 * Accordion component interaction
 * See jquery.accordion.js for details
 */

 (function($){
   $(document).ready(function(){
     $('.accordion').each(function () {
       $(this).accordion({
         itemSelector: '.accordion__item',
         headerSelector: '.accordion__title-wrapper',
         contentSelector: '.accordion__content-wrapper'
       });
     });
   });
 })(jQuery);

/**
 * Components.AccordionGrid is a jQuery friendly plugin with an exposed JS API.
 * `component` is an alias for Components.AccordionGrid object, which doubles as
 * the constructor function.
 *
 * State classes:
 *   .is-expanded - An accordion item when it's expanded.
 *
 * On DOM-ready, all elements with the `accordion-grid` class will automatically
 * be instantiated.
 *
 * Initialize yourself using jQuery `.tabAccordionGrid()`:
 *   $('.my-element').tabAccordionGrid();
 *
 * API Examples:
 *   Components.AccordionGrid.closeItems()
 *
 *   var myAccordionGrid = $('.element')[0].AccordionGrid;
 *   myAccordionGrid.openItem();
 *   myAccordionGrid.closeItem();
 *
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a constructor and base object for this component's data and functions.
Components.AccordionGrid = function (element, options) {
  // Set up internal properties.
  this.defaultOptions = {
    itemSelector: '.accordion-grid__item',
    teaserSelector: '.accordion-grid__teaser',
    detailSelector: '.accordion-grid__detail',
    closeClass: 'accordion-grid__close',
    animation: {
      duration: 750,
      easing: 'easeInOutQuart'
    }
  };
  this.$element = $(element);

  // Use the init options, with defaults.
  this.options = {
    ...this.defaultOptions,
    ...options,
  };

  // Initialize this instance.
  this.init();
};

// Closure to encapsulate and provide the component object and jQuery in the local scope.
(function (AccordionGrid, $) {

  /**
   * Component state and variables.
   */
  AccordionGrid.jQueryPluginName = 'tabAccordionGrid';
  AccordionGrid.instances = [];

  /**
   * DOM-ready callback.
   *
   * @param {Object} $
   *   jQuery
   */
  AccordionGrid.ready = function () {
    // Initialize every instance on the page.
    $('.accordion-grid').tabAccordionGrid();
  };

  /**
   * Initialize the component (jQuery plugin).
   */
  AccordionGrid.prototype.init = function () {
    const _this = this;
    const $accordionGrid = this.$element;
    const $details = $accordionGrid.find(this.options.detailSelector);
    const $closeLink = $('<a href="#" class="' + this.options.closeClass + '"><i class="icon icon--close-window-style2"></a>');
    let lastToggleTime = 0;

    // Adding a close link to each detail container.
    // Exclude detail containers which already contain a close link.
    $details.not($details.has('.' + this.options.closeClass)).prepend($closeLink);

    // Handle clicking on the item's teaser and use debounced function.
    $accordionGrid
      .off('click.accordion-grid-teaser')
      .on('click.accordion-grid-teaser', this.options.teaserSelector, function () {
        // Toggle the clicked item, but skip if we're still animating another item.
        const now = new Date().valueOf();
        if (now - lastToggleTime > _this.options.animation.duration) {
          // Expand or collapse the clicked item's details.
          _this.toggleItem($(this).closest(_this.options.itemSelector));
          lastToggleTime = now;
        }
      });

    // Handle closing an item when the close link is clicked.
    $accordionGrid
    .off('click.accordion-grid-close')
    .on('click.accordion-grid-close', '.' + _this.options.closeClass, function (e) {
      var $item = $(this).closest(_this.options.itemSelector);

      _this.closeItem($item);
      e.preventDefault();
    });

    // Auto open an item if a corresponding hash is in the URL
    this.hashOpen();

    // Append to our instances array.
    AccordionGrid.instances.push($accordionGrid);
  };

  /**
   * Toggle a specified item open or closed in a specific instance.
   * @param {jQuery object} $item The item to be toggled
   */
  AccordionGrid.prototype.toggleItem = function ($item) {
    if ($item.hasClass('is-expanded')) {
      this.closeItem($item);
    }
    else {
      this.openItem($item);
    }
  };

  /**
   * Open a specified item in a specific instance.
   * @param {jQuery object} $item - The item to be opened
   */
  AccordionGrid.prototype.openItem = function ($item) {
    const id = $item.attr('id');

    // Collapse any expanded items
    this.closeItems();

    // Expand the specified item's details
    $item.addClass('is-expanded');
    $item.find(this.options.detailSelector).slideHeight('down', this.options.animation);

    // Push the current state to the URL
    if ((id.length > 1) && (history.replaceState)) {
      history.replaceState(undefined, undefined, '#' + id);
    }
  };

  /**
   * Close a specified item in a specific instance.
   * @param {jQuery object} $item The item to be closed
   */
  AccordionGrid.prototype.closeItem = function ($item) {
    const hash = window.location.hash;

    if ($item.hasClass('is-expanded')) {
      $item.removeClass('is-expanded');
      $item.find(this.options.detailSelector).slideHeight('up', this.options.animation);
    }

    // Clear the hash of a given item if it's set in the URL
    if (hash.length > 1 && $item.is(hash) && history.replaceState) {
      history.replaceState(undefined, undefined, window.location.pathname);
    }
  };

  /**
   * Close all items in a specific instance
   * @return {jQuery object} collection of all items that were closed.
   */
  AccordionGrid.prototype.closeItems = function () {
    const _this = this;
    const $accordionGrid = this.$element;
    const $openItems = $accordionGrid.find(this.options.itemSelector).filter('.is-expanded');

    // Collapse all item details and remove state class
    $openItems.each(function () {
      _this.closeItem($(this));
    });

    // Return items that were closed.
    return $openItems;
  };

  /**
   * Check for a hash in the URL and open any corresponding accordion item.
   */
  AccordionGrid.prototype.hashOpen = function () {
    const hash = window.location.hash;

    // If the hash exists (e.g. #something) and it matches using jQuery selection.
    if (hash.length > 1 && this.$element.find(hash).length) {
      this.openItem($(hash), {
        animation: {duration: 0}
      });
    }
  };

  // Lightweight constructor.
  $.fn[AccordionGrid.jQueryPluginName] = function (options) {
    return this.each(function initPlugin() {
      const plugin = new AccordionGrid(this, options);
      $.data(this, 'plugin_' + AccordionGrid.jQueryPluginName, plugin);

      // Expose the plugin so methods can be called externally
      //   e.g., element.AccordionGrid.close();
      this.AccordionGrid = plugin;

      // Trigger custom initialized event on the element.
      $(this).trigger('initialized');
    });
  };

  // DOM-ready handler.
  $(AccordionGrid.ready);

  // AJAX handling magic.
  $(document).on('components:reattach', AccordionGrid.ready);

}(Components.AccordionGrid, jQuery));

/**
 * Countdown Clock component interaction
 * This script takes the client's current time and subtracts it from a given countdown deadline.
 * It modifies the days, hours, and minutes on the countdown timer component.
 */

(function ($) {
  let convertEventTime;

  $(document).ready(function () {
    initCountdown();
  });


  function initCountdown() {
    const $clock = $('.countdown-clock');

    // Exit if no countdowns exist on the page.
    if (!$clock.length) {
      return;
    }

    const clockDeadline = $clock.attr('data-clock-deadline');
    const now = new Date().getTime();
    // Grab the countdown-clock element and convert the provided ISO 8601
    // datetime string into a js date object. We'll use this as a UTC datetime
    // with no offset and caculate the difference in the update function.
    // For an easy time converter: https://savvytime.com/converter/pst-to-utc.
    convertEventTime = new Date(clockDeadline).getTime();

    if (convertEventTime > now) {
      updateCountdown();
    } else {
      setTimeZero();
    }
  }

  function updateCountdown() {
    // Calculate the time difference between the deadline time and the current
    // time. This will be in milliseconds.
    const currentTime = new Date().getTime();
    const timeDiff = convertEventTime - currentTime;

    if (timeDiff < 0) {
      setTimeZero();
      return;
    }

    // Calculate out the days.
    let msecDiff = timeDiff;
    let days = Math.floor(msecDiff / (1000 * 60 * 60 * 24));

    // Subtract out the days and calculate the hours.
    msecDiff -= days * 1000 * 60 * 60 * 24;
    let hours = Math.floor(msecDiff / (1000 * 60 * 60));

    // Subtract out the hours and calculate the minutes.
    msecDiff -= hours * 1000 * 60 * 60;
    let minutes = Math.floor(msecDiff / (1000 * 60));

    // Subtract out the minutes and calculate the seconds.
    msecDiff -= minutes * 1000 * 60;
    let seconds = Math.floor(msecDiff / 1000);

    // Show how much time is left per calculated unit. Grab each of the
    // elements for days/hours/minutes so we can modify them.
    $('.countdown-clock__days').text(days);
    $('.countdown-clock__hours').text(hours);
    $('.countdown-clock__minutes').text(minutes);
    $('.countdown-clock__seconds').text(seconds);

     // Update the counter approximately every 1 second.
    setTimeout(updateCountdown, 1000);
  }

  function setTimeZero() {
    $('.countdown-clock__days').text(0);
    $('.countdown-clock__hours').text(0);
    $('.countdown-clock__minutes').text(0);
    $('.countdown-clock__seconds').text(0);
  }

}(jQuery));

/**
 * Currency switcher interactions
 */
(function ($, cookies) {
  $(document).ready(function () {
    // Get variables for currency switcher component.
    const $currencySwitcher = $('.currency-switcher');
    const $currencySwitcherOptions = $('.currency-switcher__options');

    // Upon clicking the currency switcher options, we're going to set the corresponding active currency.
    if ($currencySwitcher.length) {
      $currencySwitcherOptions.on('change', function () {
        // Get variables for currency data.
        let $dataCurrency = this.value;

        // When the currency option is selected, set active class for price tag.
        $('.price-tag__price span').removeClass('is-active');
        $('.price-tag__price span[data-currency="' + $dataCurrency + '"]').addClass('is-active');

        // Set the cookie.
        cookies.set('tableauCurrency', $dataCurrency, {
          expires: 365,
          domain: '.tableau.com'
        });
      });

      // Set active currency based on cookie.
      if (cookies.get('tableauCurrency')) {
        // Set selected attribute on currency switcher option.
        $('option[value="' + cookies.get('tableauCurrency') + '"]').prop('selected', true);

        // Set correct currency.
        $('.price-tag__price span').removeClass('is-active');
        $('.price-tag__price span[data-currency="' + cookies.get('tableauCurrency') + '"]').addClass('is-active');
      }
    }
  });
})(jQuery, Cookies);

/**
 * Flyout content component interaction
 * See jquery.contentFlyout.js for details
 */

(function ( $ ) {
  $(document).ready(function(){
    $('.flyout__content').contentFlyout({
      triggers: $('.flyout__trigger'),
      slideout: $('.flyout__slideout'),
      closeLinks: $('.flyout__close-link')
    });
  });
}( jQuery ));

(function($) {
  $(document).ready(function() {

    /**
     * Handles closing the notification.
     */
    $('.global-notification .global-notification__close').click(function (e) {
      e.preventDefault();

      $('.global-notification').slideUp();
    });
  });
})(jQuery);

/**
 * Modal message behaviors.
 *
 * - Toggle .is-open state on component, e.g when showing modal message.
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a base for this module's data and functions.
Components.modalMessage = {};

// Closure to rename Components.modalMessage
(function (component, $) {

  /**
   * Variables
   */
  component.modifiers = {
    'loading': 'modal-message--loading'
  };

  /**
   * DOM-ready callback.
   *
   * @param {Object} $
   *   jQuery
   */
  component.ready = function () {
    $('.modal-message, .modal-message__close').click(function (e) {
      if (e.target === this) {
        $('.modal-message').removeClass('is-open');
        e.preventDefault();
      }
    });
  };

  /**
   * Show modal message
   *
   * @param {string} message
   *   Optional message you want to display.
   * @param {string} type
   *   Optional parameter to alter the style of the message box.
   *   Example:
   *   'loading' - Show a loading modal message.
   */
  component.show = function (message, type) {
    var $modalMessage = $('.modal-message');

    // Initialize our modal message;
    if (!$modalMessage.length) {
      $modalMessage = $('<div class="modal-message">' +
        '<div class="modal-message__dialog">' +
        '<div class="modal-message__icon"></div>' +
        '<div class="modal-message__content"></div>' +
        '<a href="#" class="modal-message__close"></a>' +
        '</div>' +
        '</div>');
      $('body').append($modalMessage);
    }

    for (var key in component.modifiers) {
      if (type === key) {
        $modalMessage.addClass(component.modifiers[key]);
      }
      else {
        $modalMessage.removeClass(component.modifiers[key]);
      }
    }

    // Replace the content of our message.
    if (message) {
      component.update(message);
    }

    // Show our modal message.
    if (!$modalMessage.hasClass('is-open')) {
      $modalMessage.addClass('is-open');
    }
  };

  /**
   * Update message.
   *
   * @param {string} message
   *   Message you want to display.
   */
  component.update = function (message) {
    $('.modal-message__content').html(message);
  };

  /**
   * Close modal message
   */
  component.close = function () {
    $('.modal-message').removeClass('is-open');
  };

  // Dom ready handler.
  $(component.ready);

}(Components.modalMessage, jQuery));

(function($) {
  $.fn.moveProgressBar = function (progress) {
    var $el = $(this),
        $progress = $el.find('.progress'),
        progress = progress || parseInt($progress.data('progress')) || 0,
        treshold = [5, 50, 100],
        modifier = '';

    for (var i in treshold) {
      if (progress <= treshold[i]) {
        modifier = 'progress--' + treshold[i];
        break;
      }
    }

    // Make sure we have a valid percentage.
    progress = (progress > 100) ? 100 : progress;

    $progress.removeClass (function (index, css) {
      return (css.match (/(^|\s)progress--\S+/g) || []).join(' ');
    }).css({
      'width': progress + '%'
    }).addClass(modifier);
  };
}( jQuery ));

/** 
 * Reveal content component interaction
 * See jquery.contentReveal.js for details
 */

(function ( $ ) {
  $(document).ready(function(){
    $('.reveal__content').contentReveal({
      triggers: $('.reveal__trigger')
    });
  });
}( jQuery ));

/**
 * Tabs component interaction
 * See jquery.tabs.js for details
 */

(function ($) {
  $(document).ready(function () {
    $('.tabs__wrapper').each(function () {
      var $this = $(this),
          $triggers = $this.find('.tabs__tab-trigger'),
          $flyoutTriggers = $this.closest('.flyout__content').siblings('.flyout__slideout').find('.tabs__tab-trigger');

      $this.tabs({
        tabLinks: $this.find('.tabs__tab-link'),
        contents: $this.find('.tabs__tab-content'),
        triggers: $triggers.add($flyoutTriggers)
      });
    });
  });
}(jQuery));

/**
 * Unstyled Expandable Content
 *
 * Allows for a keyboard accessible and screen reader friendly expandable/collapsible
 * content section.
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a base for this module's data and functions.
Components.unstyledExpandableContent = {
  defaultAnimation: {
    duration: 300,
    easing: "easeInOutQuart"
  },
  instances: [],
};

(function (component, $) {
  /**
   * DOM-ready component initialization.
   */
  component.init = function () {
    // Automatically initialize all elements with a `data-unstyled-expandable-content`
    // attribute.
    $('[data-unstyled-expandable-content]').each(component.initInstance);

    // Handle automatic collapse/hide based on media query.
    component.onViewportResize();
    // Handle window resize/orientationchange event. Debounced for performance.
    $(window).on('resize.unstyled-expandable-content orientationchange.unstyled-expandable-content', () => {
      $.doTimeout(100, component.onViewportResize)
    });
  };

  /**
   * Initialize a single component instance.
   */
  component.initInstance = function () {
    const instance = {};
    instance.$controlElement = $(this);
    instance.sectionId = instance.$controlElement.data('unstyledExpandableContent');
    instance.$expandableContent = $('#' + instance.sectionId);
    instance.toggleMode = instance.$controlElement.data('toggleMode') || 'slideToggle';
    instance.hideOnInit = instance.$controlElement.data('hideOnInit') !== false;
    instance.hideMediaQuery = instance.$controlElement.data('hideMediaQuery');
    instance.animationDuration = instance.$controlElement.data('animationDuration');
    instance.animationEasing = instance.$controlElement.data('animationEasing');
    instance.animation = $.extend({}, component.defaultAnimation, {
      duration: instance.animationDuration,
      easing: instance.animationEasing
    });

    // To keep track of all component instances.
    component.instances.push(instance);

    // If we should hide with CSS, add a modifier class.
    if (instance.toggleMode === 'hideWithCss') {
      instance.$expandableContent.addClass('unstyled-expandable-content__content--hide-with-css');
    }

    // Handle automatic hide on page load.
    if (instance.hideOnInit) {
      // If using slideToggle or hideWithCss, hide it initially (without animation).
      if (instance.toggleMode === 'slideToggle' || instance.toggleMode === 'hideWithCss') {
        component.toggleInstance(instance, false, false);
      }
    }

    // Add click handler for the control element (probably a button).
    instance.$controlElement.on('click.unstyled-expandable-content', function () {
      component.toggleInstance(instance);
    });
  };

  /**
   * Toggles an instance (expanded or collapsed).
   * Supports aria attributes for assistive technology.
   * @param {object} instance
   * @param {boolean} state
   * @param {boolean} useAnimation
   */
  component.toggleInstance = function (instance, state, useAnimation) {
    const animationDuration = useAnimation !== false ? instance.animation : 0;

    // If we are toggling the current expanded state...
    if (typeof state === 'undefined') {
      state = !component.isInstanceExpanded(instance);
    }

    // Switch the states of aria-expanded and aria-hidden
    instance.$controlElement.attr('aria-expanded', state);
    instance.$expandableContent.attr('aria-hidden', !state);

    // If using slide toggle, use .slideToggle() to toggle its visibility.
    if (instance.toggleMode === 'slideToggle') {
      if (state) {
        instance.$expandableContent.slideDown(animationDuration);
      }
      else {
        instance.$expandableContent.slideUp(animationDuration);
      }
    }
  };

  /**
   * Returns whether the instance is expanded or collapsed.
   * @param {object} instance
   * @returns {boolean}
   */
  component.isInstanceExpanded = function (instance) {
    return instance.$controlElement.attr('aria-expanded') === 'true';
  }

  /**
   * Handle window resizes and switching between portrait/landscape mode.
   */
  component.onViewportResize = function () {
    component.instances.forEach(function (instance) {
      if (instance.hideMediaQuery && window.matchMedia) {
        const mediaQueryMatch = window.matchMedia(instance.hideMediaQuery).matches;
        if (instance.lastMediaQueryMatch !== mediaQueryMatch) {
          component.toggleInstance(instance, !mediaQueryMatch, false);
        }
        instance.lastMediaQueryMatch = mediaQueryMatch;
      }
    })
  };

  $(document).ready(component.init);
}(Components.unstyledExpandableContent, jQuery));

(function ($) {
  // Bind to document ready and custom components:reattach event so this works on
  // dynamically loaded AJAX content.
  $(document).on('ready components:reattach', function (e, context) {

    /**
     * Handles making the whole row clickable
     * Makes the assumption that there exists exactly one .table-list__link in a
     * --clickable-row
     */
    $('.table-list--clickable-row tbody tr')
    .off('click.tableList')
    .on('click.tableList', function (e) {
      const $tableLink = $(this).find('.table-list__link a');
      const selection = window.getSelection();

      // Handle clicking non-anchor/button elements.
      if (e.target.nodeName !== 'A' && e.target.nodeName !== 'BUTTON') {
        // Abort handler if a non-empty text selection exists since the user was
        // probably trying to copy some text inside the table list row.
        if (selection && selection.toString().length) {
          return false;
        }

        // Default action is to trigger the tableLink at this point.
        if ($tableLink.length) {
          $tableLink.get(0).click();
        }
      }
    });
  });
})(jQuery);

/**
 * Components.DropdownNav is a jQuery friendly plugin with an exposed JS API.
 * `component` is an alias for Components.DropdownNav object, which doubles as the
 * constructor function.
 *
 * State classes:
 *   .is-open - the component when expanded / open.
 *
 * On DOM-ready, all elements with the `dropdown-nav` class will automatically be
 * instantiated.
 *
 * Initialize yourself using jQuery `.tabDropdownNav()`:
 *   $('.my-element').tabDropdownNav();
 *
 * API Examples:
 *   Components.DropdownNav.closeAll()
 *
 *   var myDropdownNav = $('.element')[0].DropdownNav;
 *   myDropdownNav.close();
 *   myDropdownNav.open();
 *
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a constructor and base object for this component's data and functions.
Components.DropdownNav = function (element, options) {
  // Set up internal properties.
  this.defaultOptions = {};
  this.$element = $(element);

  // Use the init options.
  this.options = $.extend({}, this.defaultOptions, options);

  // Initialize this instance.
  this.init();
};

// Closure to encapsulate and provide the component object and jQuery in the local scope.
(function (DropdownNav, $) {

  /**
   * Component state and variables.
   */

  // Public
  DropdownNav.instances = [];

  // Private
  var jQueryPluginName = 'tabDropdownNav';
  var isDesktop = Components.utils.breakpoint('desktop');

  /**
   * DOM-ready callback.
   *
   * @param {Object} $
   *   jQuery
   */
  DropdownNav.ready = function () {
    // Initialize every instance on the page.
    $('.dropdown-nav').tabDropdownNav();

    // Close all instances when clicking "outside" or when ESC key is pressed.
    $(document).on('click.dropdownNav', function (e) {
      DropdownNav.closeAll();
    })
    .on('keydown.dropdownNav', function (e) {
      if (e.keyCode === 27) {
        DropdownNav.closeAll();
      }
    });
  };

  /**
   * Event handler for viewport resize / orientation change.
   */
  DropdownNav.onViewportResize = function () {
    // Update internal state about whether this is a desktop+ sized viewport.
    isDesktop = Components.utils.breakpoint('desktop');
  };

  /**
   * Event handler for mouse pointer hovering over an element.
   */
  DropdownNav.onFocusOrBlur = function (e) {
    var $dropdownNav = this.$element;

    // Only for Desktop mode since we handle click events on mobile/tablet separately.
    if (!isDesktop) {
      return;
    }

    if (e.type === 'focus') {
      DropdownNav.closeAll();
      $dropdownNav.addClass('is-open');
    }
    else if (e.type === 'blur') {
      // Schedule a blur-triggered closing after a short delay.
      $dropdownNav.doTimeout('blur', 400, function () {
        // Only close if there are no focused links/buttons within.
        $dropdownNav.not($dropdownNav.has(':focus')).removeClass('is-open');
      });
    }
  };

  /**
   * Event handler for mouse pointer hovering over an element.
   */
  DropdownNav.onHoverOver = function () {
    var $dropdownNav = this.$element;
    $dropdownNav.doTimeout('open', 200, function () {
      if (isDesktop) {
        $dropdownNav.addClass('is-open');
      }
    });
  };

  /**
   * Event handler for mouse pointer leaving (un-hovering) an element.
   */
  DropdownNav.onHoverOut = function () {
    var $dropdownNav = this.$element;
    $dropdownNav.doTimeout('open', 200, function () {
      if (isDesktop) {
        $dropdownNav.removeClass('is-open');
      }
    });
  };

  /**
   * Initialize the component (jQuery plugin).
   */
  DropdownNav.prototype.init = function () {
    var $dropdownNav = this.$element;
    var $dropdownNavBody = $dropdownNav.find('.dropdown-nav__body');
    var $dropdownNavToggle = $dropdownNav.find('.dropdown-nav__toggle');
    var $dropdownNavLinks = $dropdownNav.find('.dropdown-nav__menu-link');

    // Register resize, orientationchange event handlers with debounce to
    // run logic at a more reasonable rate.
    $(window).on('resize.dropdownNav orientationchange.dropdownNav', () => {
      $dropdownNav.doTimeout(100, DropdownNav.onViewportResize)
    });

    // Handle clicks on the body element. Prevent bubbling up to the document.
    $dropdownNavBody.on('touchstart.dropdownNav', function (e) {
      e.stopPropagation();
    });

    // Register focus, blur event handlers on toggle element.
    $dropdownNavToggle.add($dropdownNavLinks).on('focus.global-header blur.global-header', DropdownNav.onFocusOrBlur.bind(this));

    // Register click event handlers on toggle element.
    $dropdownNavToggle.on('click.dropdownNav', function (e) {
      // Prevent bubbling up which results in a closeAll().
      e.stopPropagation();
      let expanded = false;
      const toggle = $dropdownNav.find('.dropdown-nav__toggle');

      if (!isDesktop) {
        $dropdownNav.toggleClass('is-open');
      }

      if ($dropdownNav.hasClass('is-open')) {
        expanded = true;
      }

      toggle.attr('aria-expanded', expanded);
    });

    // Handling for hover interaction of dropdown navs.
    //
    // Uses the doTimeout jQuery utility to handle throttling and waiting on a small delay
    // before showing the drawer (essentially hoverintent).
    $dropdownNav.hover(DropdownNav.onHoverOver.bind(this), DropdownNav.onHoverOut.bind(this));

    // Append to our instances array.
    DropdownNav.instances.push($dropdownNav);
  };

  /**
   * Open a specific instance.
   */
  DropdownNav.prototype.open = function () {
    this.$element.addClass('is-open');
  };

  /**
   * Close a specific instance.
   */
  DropdownNav.prototype.close = function () {
    this.$element.removeClass('is-open');
  };

  /**
   * Close all open instances, e.g., on blur.
   */
  DropdownNav.closeAll = function () {
    $.each(DropdownNav.instances, function (index, $dropdownNav) {
      $dropdownNav.removeClass('is-open');
    });
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[jQueryPluginName] = function (options) {
    return this.each(function initPlugin() {
      // Prevent multiple instantiations.
      if (this.DropdownNav) {
        return;
      }

      var plugin = new DropdownNav(this, options);

      // Store the plugin instance as a data property.
      $.data(this, 'plugin_' + jQueryPluginName, plugin);

      // Expose the plugin so methods can be called externally
      //   e.g., element.DropdownNav.close();
      this.DropdownNav = plugin;

      // Trigger custom initialized event on the element.
      $(this).trigger('initialized');
    });
  };

  // DOM-ready handler.
  $(DropdownNav.ready);

}(Components.DropdownNav, jQuery));

(function ($) {
  /**
   * Callback function to insert the menu into the DOM.
   */
  window.tabAjaxMenu = function (data) {
    var commands = {
      insert: function (response) {
        $(response.selector)[response.method](response.data);
      }
    };

    // Execute our commands.
    for (var i in data) {
      if (data[i]['command'] && commands[data[i]['command']]) {
        commands[data[i]['command']](data[i]);
      }
    }

    // Trigger event when our menu has been loaded.
    $(document).trigger('tabAjaxMenu:ready');

    // Attach customer menu behaviors when it's ready.
    if (typeof $.fn.tabDropdownNav === 'function') {
      $('.dropdown-nav').tabDropdownNav();
    }
  };
})(jQuery);

/**
 * Global search bar interaction
 */
(function ($) {
  $(document).ready(function () {
    var $globalHeader = $('.global-header');
    var globalHeaderData = $globalHeader.data();
    var $searchWrapper = $('.global-header__search');
    var $searchInput = $('.global-header__search-input');
    var $searchClose = $('.global-header__search-close');

    // External sites can override the search submit to redirect to www.tableau.com's
    // search page, using the data-www-search="all" type data attribute.
    if (globalHeaderData && globalHeaderData.wwwSearch) {
      $searchInput.on('submit-search.global-header-search', function () {
        window.location = 'https://www.tableau.com/search/' + globalHeaderData.wwwSearch + '/' + encodeURIComponent($(this).val());
      })
      .on('keydown.global-header-search', function (e) {
        if (e.keyCode === 13) {
          $(this).trigger('submit-search');
        }
      })
    }

    // Bind the click event on the global-header, in case the target element is AJAX-loaded.
    $globalHeader.on('click.global-header-search', '.global-header__search-toggle', function (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      e.preventDefault();
      $globalHeader.addClass('global-header--search-shown');

      // Nasty setTimeout to ensure search is visible before focusing the input.
      setTimeout(function () {
        // Make sure to focus the search field when opened.
        $searchWrapper.find('input[form="coveo-dummy-form"], input[type="search"]').focus();
      }, 200);
    });

    $searchClose.on('click.global-header-search', function (e) {
      e.stopPropagation();
      e.preventDefault();
      $globalHeader.removeClass('global-header--search-shown');
    });
  });
})(jQuery);

(function ($) {
  /**
   * Checks for mobile buttons.
   * This originally came from Drupal.olivero so we needed to improvise for the
   * current installation.
   */
  function isDesktopNav() {
    let navButtons = document.querySelector('.mobile-buttons');
    return getComputedStyle(navButtons).getPropertyValue('display') === 'none';
  }

  /**
   * Checks if navWrapper contains "is-active" class.
   * @param {object} navWrapper
   *   Header navigation.
   * @return {boolean}
   *   True if navWrapper contains "is-active" class, false if not.
   */
  function isNavOpen(navWrapper) {
    return navWrapper.classList.contains('is-active');
  }

  /**
   * Opens or closes the header navigation.
   * @param {object} props
   *   Navigation props.
   * @param {boolean} state
   *   State which to transition the header navigation menu into.
   */
  function toggleNav(props, state) {
    const value = !!state;
    props.navButton.setAttribute('aria-expanded', value);

    if (value) {
      props.body.classList.add('js-blog-overlay-active');
      props.body.classList.add('js-fixed');
      props.navWrapper.classList.add('is-active');
    }
    else {
      props.body.classList.remove('js-blog-overlay-active');
      props.body.classList.remove('js-fixed');
      props.navWrapper.classList.remove('is-active');
    }
  }

  /**
   * Init function for header navigation.
   * @param {object} props
   *   Navigation props.
   */
  function init(props) {
    props.navButton.setAttribute('aria-controls', props.navWrapperId);
    props.navButton.setAttribute('aria-expanded', 'false');

    props.navButton.addEventListener('click', function () {
      toggleNav(props, !isNavOpen(props.navWrapper));
    });
    // Closes any open sub navigation first, then close header navigation.
    document.addEventListener('keyup', function (e) {
      if (e.key === 'Escape') {
        if (props.olivero && props.olivero.areAnySubNavsOpen()) {
          props.olivero.closeAllSubNav();
        }
        else {
          toggleNav(props, false);
        }
      }
    });

    // Focus trap.
    props.navWrapper.addEventListener('keydown', function (e) {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === props.firstFocusableEl && !props.olivero.isDesktopNav()) {
            props.navButton.focus();
            e.preventDefault();
          }
        }
        else if (document.activeElement === props.lastFocusableEl && !props.olivero.isDesktopNav()) {
          props.navButton.focus();
          e.preventDefault();
        }
      }
    });
    window.addEventListener('resize', function () {
      if (isDesktopNav()) {
        toggleNav(props, false);
        props.body.classList.remove('js-blog-overlay-active', 'js-fixed');
      }
    });
  }

  /**
   * Initialize the navigation JS.
   * This was modified by Palantir to include a functionalble calling for
   * triggering the menu.
   */
  function initializeMenu() {
    const navWrapperId = 'blog-nav';
    const navWrapper = document.querySelector("#".concat(navWrapperId, ":not(.").concat(navWrapperId, "-processed)"));

    if (navWrapper) {
      navWrapper.classList.add("".concat(navWrapperId, "-processed"));
      const navButton = document.querySelector('.mobile-blog-nav-button');
      const body = document.querySelector('body');
      const focusableNavElements = navWrapper.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const firstFocusableEl = focusableNavElements[0];
      const lastFocusableEl = focusableNavElements[focusableNavElements.length - 1];

      init({
        navWrapperId: navWrapperId,
        navWrapper: navWrapper,
        navButton: navButton,
        body: body,
        firstFocusableEl: firstFocusableEl,
        lastFocusableEl: lastFocusableEl
      });
    }
  }

  // Trigger this JS after HTML.
  $(document).one('tabAjaxMenu:ready', initializeMenu);
})(jQuery);

/**
* DO NOT EDIT THIS FILE.
* See the following change record for more information,
* https://www.drupal.org/node/2815083
* @preserve
**/


if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;

    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

(function () {

  /**
   * Wait for it.
   */
  const debounce = (fn, time) => {
    let timeout;

    return function() {
      const functionCall = () => fn.apply(this, arguments);

      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    }
  }

  /**
   * Checks for mobile buttons.
   * This originally came from Drupal.olivero so we needed to improvise for the
   * current installation.
   */
  function isDesktopNav() {
    let navButtons = document.querySelector('.mobile-buttons');
    return getComputedStyle(navButtons).getPropertyValue('display') === 'none';
  }

  /**
   * Get siblings of element.
   *
   * @param {element} The element.
   */
  function getSiblings(elem) {
    // Setup siblings array and get the first sibling
    let siblings = [];
    let sibling = elem.parentNode.firstChild;

    // Loop through each sibling and push to the array
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling
    }
    return siblings;
  }

  /**
   * Shows and hides the specified menu item's second level submenu.
   *
   * @param {element} topLevelMenuITem - the <li> element that is the container for the menu and submenus.
   * @param {boolean} [toState] - Optional state where we want the submenu to end up.
   */
  function toggleSubNav(topLevelMenuITem, toState) {
    const button = topLevelMenuITem.querySelector('.blog-nav__button-toggle, .blog-nav__menu-link--button');
    const state = toState !== undefined ? toState : button.getAttribute('aria-expanded') !== 'true';
    const siblings = getSiblings(topLevelMenuITem);

    if (state) {
      button.setAttribute('aria-expanded', 'true');
      topLevelMenuITem.querySelector('.blog-nav__menu').classList.add('is-active');

      siblings.forEach(function (el) {
        if (el.classList.contains('blog-nav__menu-item--has-children')) {
          let siblingsChildren = el.querySelectorAll('.blog-nav__menu');
          siblingsChildren.forEach((uls) => {
            uls.classList.remove('is-active')
          });

          siblingsChildren = el.querySelectorAll('.blog-nav__button-toggle');
          siblingsChildren.forEach((button) => {
            button.setAttribute('aria-expanded', 'false')
          });

        }
      });

    } else {
      button.setAttribute('aria-expanded', 'false');
      topLevelMenuITem.querySelector('.blog-nav__menu').classList.remove('is-active');
    }
  }

  /**
   * Initialize the navigation JS.
   * This was modified by Palantir to include a functionalble calling for
   * triggering the menu.
   */
  function initializeMenuBlog() {
    const secondLevelNavMenus = document.querySelectorAll('.blog-nav__menu-item--has-children');

    secondLevelNavMenus.forEach(function (el) {
      const button = el.querySelector('.blog-nav__button-toggle, .blog-nav__menu-link--button, .blog-nav__menu-link--nolink');

      if (button) {
        button.removeAttribute('aria-hidden');
        button.removeAttribute('tabindex');
        button.addEventListener('click', function (e) {
          const topLevelMenuITem = e.currentTarget.parentNode;
          toggleSubNav(topLevelMenuITem);
        });
      }

      el.addEventListener('mouseenter', function (e) {
        if (isDesktopNav()) {
          debounce(toggleSubNav(e.currentTarget, true), 1000);
        }
      });
      el.addEventListener('mouseleave', function (e) {
        if (isDesktopNav()) {
          debounce(toggleSubNav(e.currentTarget, false), 1000);
        }
      });
      el.addEventListener('keydown', function (e) {
        if (e.key === 'Enter' || e.key === ' ' || e.code === 'Space') {
          if (isDesktopNav()) {
            if (e.target.getAttribute('aria-expanded') == 'true') {
              debounce(toggleSubNav(e.currentTarget, false), 1000);
            }
            else {
              debounce(toggleSubNav(e.currentTarget, true), 1000);
            }
          }
        }
      });
    });

    let secondaryLinksWithChildren = document.querySelectorAll('.blog-nav__menu-item--level-2.blog-nav__menu-item--has-children');
    secondaryLinksWithChildren.forEach(function (el) {
      el.addEventListener('mouseenter', function (e) {
        el.querySelector('.blog-nav__menu--level-3').style.left = el.parentNode.offsetWidth + 'px';
      });
    });

    // On hitting escape key, close all the subnavs.
    document.addEventListener('keyup', function (e) {
      if (e.keyCode === 27 && isDesktopNav()) {
        closeAllSubNav();
      }
    });
  }

  /**
   * Close all second level sub navigation menus.
   */
  function closeAllSubNav() {
    const secondLevelNavMenus = document.querySelectorAll('.blog-nav__menu-item--has-children');
    secondLevelNavMenus.forEach(function (el) {
      toggleSubNav(el, false);
    });
  }

  /**
   * Checks if any sub navigation items are currently active.
   * @return {boolean} If sub nav is currently open.
   */
  function areAnySubNavsOpen() {
    const secondLevelNavMenus = document.querySelectorAll('.blog-nav__menu-item--has-children');
    let subNavsAreOpen = false;
    secondLevelNavMenus.forEach(function (el) {
      const button = el.querySelector('.blog-nav__button-toggle');
      const state = button.getAttribute('aria-expanded') === 'true';

      if (state) {
        subNavsAreOpen = true;
      }
    });
    return subNavsAreOpen;
  }

  // Trigger this JS after HTML.
  $(document).one('tabAjaxMenu:ready', initializeMenuBlog);
})();

(function ($) {
  /**
   * Checks for mobile buttons.
   * This originally came from Drupal.olivero so we needed to improvise for the
   * current installation.
   */
  function isDesktopNav() {
    let navButtons = document.querySelector('.mobile-buttons');
    return getComputedStyle(navButtons).getPropertyValue('display') === 'none';
  }

  /**
   * Checks if navWrapper contains "is-active" class.
   * @param {object} navWrapper
   *   Header navigation.
   * @return {boolean}
   *   True if navWrapper contains "is-active" class, false if not.
   */
  function isNavOpen(navWrapper) {
    return navWrapper.classList.contains('is-active');
  }

  /**
   * Opens or closes the header navigation.
   * @param {object} props
   *   Navigation props.
   * @param {boolean} state
   *   State which to transition the header navigation menu into.
   */
  function toggleNav(props, state) {
    const value = !!state;
    props.navButton.setAttribute('aria-expanded', value);

    if (value) {
      props.body.classList.add('js-overlay-active');
      props.body.classList.add('js-fixed');
      props.navWrapper.classList.add('is-active');
    }
    else {
      props.body.classList.remove('js-overlay-active');
      props.body.classList.remove('js-fixed');
      props.navWrapper.classList.remove('is-active');
    }
  }

  /**
   * Init function for header navigation.
   * @param {object} props
   *   Navigation props.
   */
  function init(props) {
    props.navButton.setAttribute('aria-controls', props.navWrapperId);
    props.navButton.setAttribute('aria-expanded', 'false');

    props.navButton.addEventListener('click', function () {
      toggleNav(props, !isNavOpen(props.navWrapper));
    });
    // Closes any open sub navigation first, then close header navigation.
    document.addEventListener('keyup', function (e) {
      if (e.key === 'Escape') {
        if (props.olivero && props.olivero.areAnySubNavsOpen()) {
          props.olivero.closeAllSubNav();
        }
        else {
          toggleNav(props, false);
        }
      }
    });

    // Focus trap.
    props.navWrapper.addEventListener('keydown', function (e) {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === props.firstFocusableEl && !props.olivero.isDesktopNav()) {
            props.navButton.focus();
            e.preventDefault();
          }
        }
        else if (document.activeElement === props.lastFocusableEl && !props.olivero.isDesktopNav()) {
          props.navButton.focus();
          e.preventDefault();
        }
      }
    });
    window.addEventListener('resize', function () {
      if (isDesktopNav()) {
        toggleNav(props, false);
        props.body.classList.remove('js-overlay-active', 'js-fixed');
      }
    });
  }

  /**
   * Initialize the navigation JS.
   * This was modified by Palantir to include a functionalble calling for
   * triggering the menu.
   */
  function initializeMenu() {
    const navWrapperId = 'header-nav';
    const navWrapper = document.querySelector("#".concat(navWrapperId, ":not(.").concat(navWrapperId, "-processed)"));

    if (navWrapper) {
      navWrapper.classList.add("".concat(navWrapperId, "-processed"));
      const navButton = document.querySelector('.mobile-nav-button');
      const body = document.querySelector('body');
      const focusableNavElements = navWrapper.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const firstFocusableEl = focusableNavElements[0];
      const lastFocusableEl = focusableNavElements[focusableNavElements.length - 1];

      init({
        navWrapperId: navWrapperId,
        navWrapper: navWrapper,
        navButton: navButton,
        body: body,
        firstFocusableEl: firstFocusableEl,
        lastFocusableEl: lastFocusableEl
      });
    }
  }

  // Trigger this JS after HTML.
  $(document).one('tabAjaxMenu:ready', initializeMenu);
})(jQuery);

/**
* DO NOT EDIT THIS FILE.
* See the following change record for more information,
* https://www.drupal.org/node/2815083
* @preserve
**/


if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;

    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

(function () {

  /**
   * Wait for it.
   */
  const debounce = (fn, time) => {
    let timeout;

    return function() {
      const functionCall = () => fn.apply(this, arguments);

      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    }
  }

  /**
   * Checks for mobile buttons.
   * This originally came from Drupal.olivero so we needed to improvise for the
   * current installation.
   */
  function isDesktopNav() {
    let navButtons = document.querySelector('.mobile-buttons');
    return getComputedStyle(navButtons).getPropertyValue('display') === 'none';
  }

  /**
   * Get siblings of element.
   *
   * @param {element} The element.
   */
  function getSiblings(elem) {
    // Setup siblings array and get the first sibling
    let siblings = [];
    let sibling = elem.parentNode.firstChild;

    // Loop through each sibling and push to the array
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling
    }
    return siblings;
  }

  /**
   * Shows and hides the specified menu item's second level submenu.
   *
   * @param {element} topLevelMenuITem - the <li> element that is the container for the menu and submenus.
   * @param {boolean} [toState] - Optional state where we want the submenu to end up.
   */
  function toggleSubNav(topLevelMenuITem, toState) {
    const button = topLevelMenuITem.querySelector('.primary-nav__button-toggle, .primary-nav__menu-link--button');
    const state = toState !== undefined ? toState : button.getAttribute('aria-expanded') !== 'true';
    const siblings = getSiblings(topLevelMenuITem);

    if (state) {
      button.setAttribute('aria-expanded', 'true');
      topLevelMenuITem.querySelector('.primary-nav__menu').classList.add('is-active');

      siblings.forEach(function (el) {
        if (el.classList.contains('primary-nav__menu-item--has-children')) {
          let siblingsChildren = el.querySelectorAll('.primary-nav__menu');
          siblingsChildren.forEach((uls) => {
            uls.classList.remove('is-active')
          });

          siblingsChildren = el.querySelectorAll('.primary-nav__button-toggle');
          siblingsChildren.forEach((button) => {
            button.setAttribute('aria-expanded', 'false')
          });

        }
      });

    } else {
      button.setAttribute('aria-expanded', 'false');
      topLevelMenuITem.querySelector('.primary-nav__menu').classList.remove('is-active');
    }
  }

  /**
   * Initialize the navigation JS.
   * This was modified by Palantir to include a functionalble calling for
   * triggering the menu.
   */
  function initializeMenu2() {
    const secondLevelNavMenus = document.querySelectorAll('.primary-nav__menu-item--has-children');

    secondLevelNavMenus.forEach(function (el) {
      const button = el.querySelector('.primary-nav__button-toggle, .primary-nav__menu-link--button, .primary-nav__menu-link--nolink');

      if (button) {
        button.removeAttribute('aria-hidden');
        button.removeAttribute('tabindex');
        button.addEventListener('click', function (e) {
          const topLevelMenuITem = e.currentTarget.parentNode;
          toggleSubNav(topLevelMenuITem);
        });
      }

      el.addEventListener('mouseenter', function (e) {
        if (isDesktopNav()) {
          debounce(toggleSubNav(e.currentTarget, true), 1000);
        }
      });
      el.addEventListener('mouseleave', function (e) {
        if (isDesktopNav()) {
          debounce(toggleSubNav(e.currentTarget, false), 1000);
        }
      });
    });

    let secondaryLinksWithChildren = document.querySelectorAll('.primary-nav__menu-item--level-2.primary-nav__menu-item--has-children');
    secondaryLinksWithChildren.forEach(function (el) {
      el.addEventListener('mouseenter', function (e) {
        el.querySelector('.primary-nav__menu--level-3').style.left = el.parentNode.offsetWidth + 'px';
      });
    });

    // On hitting escape key, close all the subnavs.
    document.addEventListener('keyup', function (e) {
      if (e.keyCode === 27 && isDesktopNav()) {
        closeAllSubNav();
      }
    });
  }

  /**
   * Close all second level sub navigation menus.
   */
  function closeAllSubNav() {
    const secondLevelNavMenus = document.querySelectorAll('.primary-nav__menu-item--has-children');
    secondLevelNavMenus.forEach(function (el) {
      toggleSubNav(el, false);
    });
  }

  /**
   * Checks if any sub navigation items are currently active.
   * @return {boolean} If sub nav is currently open.
   */
  function areAnySubNavsOpen() {
    const secondLevelNavMenus = document.querySelectorAll('.primary-nav__menu-item--has-children');
    let subNavsAreOpen = false;
    secondLevelNavMenus.forEach(function (el) {
      const button = el.querySelector('.primary-nav__button-toggle');
      const state = button.getAttribute('aria-expanded') === 'true';

      if (state) {
        subNavsAreOpen = true;
      }
    });
    return subNavsAreOpen;
  }

  // Trigger this JS after HTML.
  $(document).one('tabAjaxMenu:ready', initializeMenu2);
})();

(function ($) {
  /**
   * Checks for mobile buttons.
   * This originally came from Drupal.olivero so we needed to improvise for the
   * current installation.
   */
  function isDesktopNav() {
    let navButtons = document.querySelector('.dropdown-navs--section-nav .mobile-buttons');
    return getComputedStyle(navButtons).getPropertyValue('display') === 'none';
  }

  /**
   * Checks if navWrapper contains "is-active" class.
   * @param {object} navWrapper
   *   Header navigation.
   * @return {boolean}
   *   True if navWrapper contains "is-active" class, false if not.
   */
  function isNavOpen(navWrapper) {
    return navWrapper.classList.contains('is-active');
  }

  /**
   * Opens or closes the header navigation.
   * @param {object} props
   *   Navigation props.
   * @param {boolean} state
   *   State which to transition the header navigation menu into.
   */
  function toggleNav(props, state) {
    const value = !!state;
    props.navButton.setAttribute('aria-expanded', value);

    if (value) {
      props.body.classList.add('js-overlay-active');
      props.body.classList.add('js-fixed');
      props.navWrapper.classList.add('is-active');
    }
    else {
      props.body.classList.remove('js-overlay-active');
      props.body.classList.remove('js-fixed');
      props.navWrapper.classList.remove('is-active');
    }
  }

  /**
   * Init function for header navigation.
   * @param {object} props
   *   Navigation props.
   */
  function init(props) {
    props.navButton.setAttribute('aria-controls', props.navWrapperId);
    props.navButton.setAttribute('aria-expanded', 'false');

    props.navButton.addEventListener('click', function () {
      toggleNav(props, !isNavOpen(props.navWrapper));
    });
    // Closes any open sub navigation first, then close header navigation.
    document.addEventListener('keyup', function (e) {
      if (e.key === 'Escape') {
        if (props.olivero && props.olivero.areAnySubNavsOpen()) {
          props.olivero.closeAllSubNav();
        }
        else {
          toggleNav(props, false);
        }
      }
    });

    // Focus trap.
    props.navWrapper.addEventListener('keydown', function (e) {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === props.firstFocusableEl && !props.olivero.isDesktopNav()) {
            props.navButton.focus();
            e.preventDefault();
          }
        }
        else if (document.activeElement === props.lastFocusableEl && !props.olivero.isDesktopNav()) {
          props.navButton.focus();
          e.preventDefault();
        }
      }
    });
    window.addEventListener('resize', function () {
      if (isDesktopNav()) {
        toggleNav(props, false);
        props.body.classList.remove('js-overlay-active', 'js-fixed');
      }
    });
  }

  /**
   * Initialize the navigation JS.
   * This was modified by Palantir to include a functionalble calling for
   * triggering the menu.
   */
  function initializeMenu() {
    const contentWrapper = document.querySelector('.dropdown-navs--section-nav');
    if (contentWrapper) {
      const navWrapperId = contentWrapper.attributes.getNamedItem('id').value;
      const navWrapper = document.querySelector("#".concat(navWrapperId, ":not(.").concat(navWrapperId, "-processed)"));

      if (navWrapper) {
        navWrapper.classList.add("".concat(navWrapperId, "-processed"));
        const navButton = document.querySelector('.dropdown-navs--section-nav .mobile-nav-button');
        const body = document.querySelector(".dropdown-navs__content");
        const focusableNavElements = navWrapper.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableEl = focusableNavElements[0];
        const lastFocusableEl = focusableNavElements[focusableNavElements.length - 1];

        init({
          navWrapperId: navWrapperId,
          navWrapper: navWrapper,
          navButton: navButton,
          body: body,
          firstFocusableEl: firstFocusableEl,
          lastFocusableEl: lastFocusableEl
        });
      }
    }
  }

  // Trigger this JS after HTML.
  $(document).one('tabAjaxMenu:ready', initializeMenu);
})(jQuery);

/**
 * Interactions for Section Nav component.
 */
(function($) {
  $(document).ready(function() {
    var $nav = $('.section-nav'),
        $title = $nav.find('.section-nav__title, .block__title'),
        $menu = $nav.find('.section-nav__menu, .menu-block-wrapper > ul.menu'),
        animation = {
          duration: 500,
          easing: "easeInOutQuart"
        },
        sticky;

    if ($nav.length) {
      // Handle opening/closing menu on mobile/tablet
      $title.on('click', function(e) {
        if (Components.utils.breakpoint('tablet') || Components.utils.breakpoint('mobile')) {
          $nav.toggleClass('is-open');
          $menu.slideToggle(animation);
          e.preventDefault();
        }
      });

      // Handle opening/closing menu on mobile/tablet
      $nav.on('click', function(e) {
        if (e.target === this && (Components.utils.breakpoint('tablet') || Components.utils.breakpoint('mobile'))) {
          $nav.toggleClass('is-open');
          $menu.slideToggle(animation);
          e.preventDefault();
        }
      }).end()
      // There are cases where the links in a section nav do not actually link anywhere, so the
      // behavior here is to treat the link like an <option> element: clicking the link closes the nav.
      .find('a').on('click', function(e) {
        if (!$(this).attr('href') && (Components.utils.breakpoint('tablet') || Components.utils.breakpoint('mobile'))) {
          e.preventDefault();
          $nav.removeClass('is-open');
          $menu.slideUp(animation);
        }
      });

      // Sticky nav on mobile/tablet
      if (!Components.utils.breakpoint('desktop')) {
        sticky = new Waypoint.Sticky({
          element: $nav
        });
      }
    }
  });
})(jQuery);

/**
 * Sidebar nav  interaction including scroll-aware highlighting
 */

(function($){
  $(document).ready(function(){
    var $subnav = $('.subnav'),
        $links = $subnav.find('.subnav__links'),
        $linksWrapper = $links.find('.subnav__links-wrapper'),
        $anchors = $('.anchor-link');

    if ($links.length && $anchors.length) {
      $anchors.waypoint({
        handler: function(direction) {
          var id = this.element.id;
          if (direction === 'down') {
            $links.find('a[href="#' + id + '"]').parent().addClass('is-active').siblings().removeClass('is-active');
          } else if (direction === 'up') {
            $links.find('a[href="#' + id + '"]').parent().prev().addClass('is-active').siblings().removeClass('is-active');
          }
        },
        offset: $subnav.outerHeight(true)
      });

      // Handle scrolling of links on mobile if they are present.
      if ($linksWrapper.length) {
        mobileScroll();
        $(window).on('resize orientationchange', () => {
          $.doTimeout(100, mobileScroll);
        });
      }

      // Smooth Scroll for anchor links
      // @TODO generalize and separate from this component
      $links.find('a').not('.subnav__cta a').click(function(e) {
        var element = $(this).attr('href'),
            offset = $subnav.outerHeight(true) - 1;

        // Offset for mobile
        if ($subnav.find(".sticky-wrapper").length) {
          offset = $subnav.find(".sticky-wrapper").outerHeight(true) - 1;
        }

        Components.utils.smoothScrollTop($(element), 500, offset);
        e.preventDefault();
      });
    }

    // Manage scroll fading on mobile if there's overflow.
    function mobileScroll() {
      var width = $linksWrapper[0].offsetWidth,
          scrollWidth = $linksWrapper[0].scrollWidth;

      if (width < scrollWidth) {
        // Add right fade right away since we always start on the left.
        $links.addClass('fade-right');

        $linksWrapper.scroll(function () {
          var scrollPos = $linksWrapper.scrollLeft();

          // Add both fades and then remove below if needed.
          $links.addClass('fade-right fade-left');

          // Remove right fade when scrolled all the way to the right
          if (scrollPos === (scrollWidth - width)) {
            $links.removeClass('fade-right');
          }
          // Remove left fade when scrolled all the way to the left
          if (scrollPos === 0) {
            $links.removeClass('fade-left');
          }
        });
      } else {
        $links.removeClass('fade-left fade-right');
      }

    }
  });
})(jQuery);

/**
 * Loading overlay behaviors.
 *
 * - Show a loading animation w/ overlay.
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a base for this module's data and functions.
Components.loadingOverlay = {};

// Closure to rename Components.modalMessage
(function (component, $) {

  /**
   * Show loading overlay
   *
   * @param {string} $element
   *   The element on which you want to show the loading animation.
   * @param {string} message
   *   Optional message to display in the loading overlay.
   */
  component.show = function ($element, message, modifier) {
    var message = message || 'Loading...',
        $overlay = $('<div class="loading-overlay">' +
          '<div class="loader">' +
          '<div class="loader__animation"></div>' +
          '<div class="loader__message">' + message + '</div>' +
          '</div>' +
          '</div>'),
        offsetY = Components.utils.getElementViewPortCenter($element);

    // Allow custom modifier.
    if (modifier) {
      $overlay.addClass(modifier);
    }

    $overlay.find('.loader').css('top', offsetY);
    $overlay.prependTo($element)
  };

  /**
   * Hide loading overlay
   *
   * @param {string} $element
   *   The element on which you want to show the loading animation.
   * @param {int} delay
   *   Delay in milliseconds.
   */
  component.hide = function ($element, delay) {
    // Set default to 0 ms.
    delay = delay || 0;

    setTimeout(function () {
      $element.find('.loading-overlay').remove();
    }, delay);
  };

}(Components.loadingOverlay, jQuery));

/**
 * Scroll Reveal component interaction
 * See https://github.com/jlmakes/scrollreveal for documentation
 */

(function ( $ ) {
  $(document).ready(function(){
    var sequenceIntervalDefault = 100,
        movementDistance = '200px',
        defaultSettings = {
          scale: 1,
          distance: 0,
          duration: 1200,
          easing: 'cubic-bezier(0.77, 0, 0.175, 1)'
        },
        origins = ['left', 'right', 'top', 'bottom'];

    // Initiate Scroll Reveal with some default settings.
    window.scrollReveal = ScrollReveal(defaultSettings);

    // Presets for the different origin modifiers (moving into place from
    // specified direction).
    for (var i = 0; i < origins.length; i++) {
      scrollReveal.reveal('.scroll-reveal--' + origins[i], {
        origin: origins[i],
        distance: movementDistance
      });

      // Because these elements can potentially hang off the side of the page
      // and cause horizontal scrolling, we have to hide overflow on section
      // wrappers.
      $('.scroll-reveal--' + origins[i]).parents('.section').css('overflow-x', 'hidden');
    }

    // Sequenced reveals based on the default interval set above or an interval
    // specified in a data attribute on the container.
    $('.scroll-reveal__sequence-container').each(function() {
      var sequenceDelay = $(this).data('sequence-delay') || 0,
          sequenceInterval = $(this).data('sequence-interval') || sequenceIntervalDefault;

      $(this).find('.scroll-reveal--sequenced').each(function() {
        scrollReveal.reveal(this, {
          delay: sequenceDelay
        });
        sequenceDelay += sequenceInterval;
      });
    });

    // Apply any options applied via data attributes
    $('.scroll-reveal').each(function() {
      scrollReveal.reveal(this, $(this).data());
    });
  });
}( jQuery ));

/** 
 * Search Highlight utility.
 *
 * Searches through a list of items and highlights items that match the term.
 */
(function($){
  $(document).ready(function(){
    var $searches = $('.search-highlight input[type="search"]');
    
    if ($searches.length) {
      $searches.each(function(index, el) {
        var $search = $(el),
            $content = $('#' + $search.data('content')),
            highlightClass = $search.data('highlight-class') + " search-highlight__match",
            $contentItems = $content.find('li');

        $search.on('change paste keyup search', function(e) {
          var term = $(this).val().toLowerCase();
          $contentItems.each(function(index, item) {
            var text = $(item).text().toLowerCase();
            $(item).removeClass(highlightClass);
            if (term.length > 0 && text.indexOf(term) > -1) {
              $(item).addClass(highlightClass);
            }
          });
        });

      });
    }
  });
})(jQuery);

(function ($) {
  $.fn.sonarPulse = function (options) {
    var $el = $(this),
        defaults = {
          // sonarSelector {String}
          // CSS selector identifying the sonar element
          sonarSelector: '.sonar-indicator',

          // sonarElement {jQuery}
          // jQuery object containing the sonar element
          $sonarElement: $('<div class="sonar-indicator"></div>'),

          // timeout {Number}
          // milliseconds before removing the element matching the sonarSelector option
          timeout: 5000,

          // offset {Number} (deprecated)
          // a negative pixel offset from the element's X position
          offset: 5,

          // top/right/bottom/left {String}
          // position properties applied to the sonar element
          top:    '0',
          right:  '0',
          bottom: '0',
          left:   '0',

          // limitDisplayCount {Number}
          // uses localStorage to limit the number of displays (disabled when 0)
          limitDisplayCount: 0,

          // limitDisplayId {String|null}
          // a unique identifier for tracking the display count
          limitDisplayId: null
        },
        padding,
        hasLocalStorage,
        displayCount;

    // Handle deprecated options.
    if (options) {
      // Handle deprecated offset option.
      if (options.offset) {
        padding = parseInt($el.css('padding-left').replace('px', ''));
        // left replaces offset, ignoring padding, and negated.
        options.left = ((padding / 2) - options.offset) + 'px';
      }
    }

    // Extend defaults without overwriting them.
    options = $.extend({}, defaults, options);

    // Limit displays by count using localStorage API. Ignore if localStorage is not supported.
    hasLocalStorage = window.localStorage
      && typeof localStorage.getItem === 'function'
      && typeof localStorage.setItem === 'function';

    // Check localStorage support and if we are limiting the display count
    if (hasLocalStorage && options.limitDisplayId && options.limitDisplayCount > 0) {
      displayCount = localStorage.getItem('sonarPulseCount_' + options.limitDisplayId) || 0;
      // Stop here if we display count is greater than or equal to the allowed count.
      if (displayCount >= options.limitDisplayCount) {
        return;
      }
      // Save the latest value to localStorage.
      localStorage.setItem('sonarPulseCount_' + options.limitDisplayId, ++displayCount);
      // Trigger a custom event so other JS can do stuff.
      $el.trigger('sonar:activate');
    }

    // Apply positioning to the sonar element.
    options.$sonarElement.css({
      top: options.top,
      right: options.right,
      bottom: options.bottom,
      left: options.left
    });

    // Add the sonar element, ensuring only one exists.
    $el.remove(options.sonarSelector).prepend(options.$sonarElement);

    // If timeout is non-zero, remove after delay.
    if (options.timeout > 0) {
      // Remove our sonar pulse after 5 seconds.
      setTimeout(function () {
        $el.find(options.sonarSelector).remove();
        // Trigger a custom event so other JS can do stuff.
        $el.trigger('sonar:deactivate');
      }, options.timeout);
    }
  };

  // Auto-initialize on DOM ready with .sonar-pulse class.
  // Provide options using data-sonar-options attribute and JSON string:
  // data-sonar-options='{"sonarSelector": ".sonar-indicator", "timeout": 5000, "offsetY": "-10", "limitDisplayCount": 1, "limitDisplayId": "cool-thing"}'
  $(function () {
    $('.sonar-pulse').each(function () {
      var $el = $(this);

      $el.sonarPulse($el.data('sonarOptions') || {});
    });
  })
}(jQuery));

(function($) {
  $(document).ready(function() {

    /**
     * Allows making an element sticky on the page with just a 'sticky' class.
     */
    $('.sticky').each(function(i) {
      stickIt(this);
    });

    // For less capable browsers, only execute sticky on desktop.
    if (!window.matchMedia || $('.lt-ie9').length) {
      $('.sticky--desktop').each(function(i) {
        stickIt(this);
      });
      return;
    }

    if (Components.utils.breakpoint('desktop')) {
      $('.sticky--desktop').each(function(i) {
        stickIt(this);
      });
    }

    if (Components.utils.breakpoint('tablet')) {
      $('.sticky--tablet').each(function(i) {
        stickIt(this);
      });
    }

    if (Components.utils.breakpoint('mobile')) {
      $('.sticky--mobile').each(function(i) {
        stickIt(this);
      });
    }
  });

  function stickIt(el) {
    var sticky = new Waypoint.Sticky({
      element: el
    });
  }
})(jQuery);

/**
 * Thumbnail colors.
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a base for this module's data and functions.
Components.thumbnail = {};

// Closure to extend behavior, provide privacy and state.
(function (component, $) {
  var i = -1;

  // Available thumbnail colors.
  // @See $thumbnail-colors SASS variable.
  component.colors = ['dark-blue', 'teal', 'light-blue', 'light-orange', 'red', 'yellow'];

  /**
   * DOM-ready callback.
   *
   * @param {Object} $
   *   jQuery
   */
  component.ready = function ($) {
    // Initialize unique color for each thumbnail.
    $('.thumbnail--color').not('[class*="thumbnail--color-"]').each(function () {
      $(this).addClass('thumbnail--color-' + component.pickUniqueColor());
    });
  };

  /**
   * Pick a unique color.
   */
  component.pickUniqueColor = function () {
    i++;

    // If no choices are left, start back from the beginning.
    if (i < 0 || i === component.colors.length) {
      i = 0;
    }

    // Pick a color.
    return component.colors[i];
  };

})(Components.thumbnail, jQuery);

// Attach our DOM-ready callback.
jQuery(Components.thumbnail.ready);
